export interface SamplingPeriodResponse {
  id: string
  description?: string
  project_id?: string

  start_date?: string
  end_date?: string
  created_at?: string
  updated_at?: string
  deleted_at?: string
}

export class SamplingPeriodDto {
  id: string
  projectId: string
  description?: string

  createdAt?: string
  startDate?: string
  deletedAt?: string
  updatedAt?: string
  endDate?: string

  constructor(response: SamplingPeriodResponse) {
    this.startDate = response.start_date ?? ''
    this.deletedAt = response.deleted_at ?? ''
    this.id = response.id
    this.updatedAt = response.updated_at ?? ''
    this.projectId = response.project_id ?? ''
    this.createdAt = response.created_at ?? ''
    this.endDate = response.end_date ?? ''
  }

  toJSON() {
    return {
      id: this.id,
      projectId: this.projectId,
      description: this.description,
      startDate: this.startDate,
      endDate: this.endDate,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
    }
  }
}
