import { lazy } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'

import MapsFullScreenLayout from '@components/template/MapsFullScreenLayout/MapsFullScreenLayout'
import PrivateRoute from '@components/template/PrivateRoute'
import PublicRoute from '@components/template/PublicRoute'
import RootPage from '@pages/RootPage'
import ListSurveyLayout from '@components/template/ListSurveyLayout/ListSurveyLayout'

import checkpointRoutes from './checkpoint.routes'
import projectsRoute from './projects.route'
import reportsRoute from './reports.route'
import ERoutePaths from './routes.path'

const LoginPage = lazy(() => import('@pages/LoginPage/LoginPage'))
const ReportPage = lazy(() => import('@pages/ReportPage/ReportPage'))
const NotFoundPage = lazy(() => import('@pages/NotfoundPage/NotFoundPage'))
const ListSurveyPage = lazy(() => import('@pages/ListSurveyPage/ListSurveyPage'))
// const MissionOverviewPage = lazy(() => import('@pages/MissionOverviewPage/MissionOverviewPage'))
const SurveyDetailPage = lazy(() => import('@pages/SurveyDetailPage/SurveyDetailPage'))
const CreateSurveyPage = lazy(() => import('@pages/CreateSurveyPage/CreateSurveyPage'))
const EditSurveyPage = lazy(() => import('@pages/EditSurveyPage/EditSurveyPage'))

const routes = [
  /**
   * Public routes
   */
  {
    path: ERoutePaths.Login,
    element: (
      <PublicRoute title="Mozaic Earth Portal | Login">
        <LoginPage />
      </PublicRoute>
    ),
  },
  {
    path: ERoutePaths.Report,
    element: (
      <PrivateRoute title="Mozaic Earth Portal | Report">
        <ReportPage />
      </PrivateRoute>
    ),
  },
  /**
   * @deprecated
   * Homepage Routes
   */
  {
    path: ERoutePaths.Home,
    element: <RootPage />,
    children: [
      {
        index: true,
        element: <Navigate to={ERoutePaths.Projects} replace />,
      },
      projectsRoute.projects,
      reportsRoute.reports,
    ],
  },
  /**
   * Maps Routes
   */
  {
    path: ERoutePaths.Map,
    element: <MapsFullScreenLayout />,
    children: [
      {
        path: ERoutePaths.ListSurvey,
        element: <ListSurveyLayout />,
        children: [
          {
            path: ERoutePaths.ListSurvey,
            element: (
              <PrivateRoute title="Mozaic Earth Portal | Project Dashboard">
                <ListSurveyPage />
              </PrivateRoute>
            ),
          },
          {
            path: ERoutePaths.CreateSurvey,
            element: (
              <PrivateRoute title="Mozaic Earth Portal | Create Survey">
                <CreateSurveyPage />
              </PrivateRoute>
            ),
          },
          {
            path: ERoutePaths.EditSurvey,
            element: (
              <PrivateRoute title="Mozaic Earth Portal | Edit Survey">
                <EditSurveyPage />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: ERoutePaths.SurveyDetail,
        element: (
          <PrivateRoute title="Mozaic Earth Portal | Survey">
            <SurveyDetailPage />
          </PrivateRoute>
        ),
      },
    ],
  },
  checkpointRoutes.checkpointOverview,
  {
    path: '*',
    element: <NotFoundPage />,
  },
]

const router = createBrowserRouter(routes, { basename: '/' })

export default router
