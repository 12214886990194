import { useAppLoadingContext } from '@context/AppLoading'
import tileLayerService from '@services/TileLayerService/TileLayerService'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'

const useQueryTileLayersByProjectId = ({ projectId }: { projectId: string }) => {
  const query = useQuery({
    queryKey: ['tile-layers'],
    queryFn: () => tileLayerService.fetchTileLayersByProjectId(projectId),
  })
  const { setLoading, loading } = useAppLoadingContext()

  useEffect(() => {
    setLoading(prev => ({
      ...prev,
      tileLayers: query.isLoading,
    }))
  }, [query.isLoading])

  const tileLayers = useMemo(() => query.data?.data ?? null, [query.data?.data])

  return { query, loading, tileLayers }
}

export default useQueryTileLayersByProjectId
