import { MapProvider } from './MapContext'
import React, { FC } from 'react'

const withMapContext = <P extends object>(WrappedComponent: React.ComponentType<P>): FC<P> => {
  const ComponentWithMapContext: FC<P> = props => (
    <MapProvider>
      <WrappedComponent {...props} />
    </MapProvider>
  )

  return ComponentWithMapContext
}

export default withMapContext
