import { IBaseResponse } from '@models/common.model'
import { LabelSetDto } from '@models/labelSet.model'
import supabaseClient, { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'
import _ from 'lodash'

export class LabelSetService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  fetchAllLabelSets = async (): Promise<IBaseResponse<LabelSetDto[]>> => {
    try {
      const response = await supabaseClient.from('label_sets').select('*')

      if (!response || response.error) {
        return { data: null, error: response?.error }
      }
      const labelSets = response.data.map(item => new LabelSetDto(item))
      return { data: labelSets, error: null }
    } catch (error: any) {
      return { data: null, error }
    }
  }
}

// Singleton pattern
const labelSetService = new LabelSetService()

export default labelSetService
