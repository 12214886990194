import TextField, { TextFieldProps } from '@components/atom/TextField'
import { FormControl, InputLabelProps } from '@mui/material'
import { Controller, ControllerProps, useFormContext } from 'react-hook-form'

export type TextAreaFormFieldProps = {
  name: string
  label?: string
  hint?: string
  textFieldProps?: TextFieldProps
  inputLabelProps?: InputLabelProps
  onSubmit?: any
} & Omit<ControllerProps, 'render'>

const TextAreaFormField = ({ label, textFieldProps = {}, inputLabelProps = {}, control, onSubmit, ...rest }: TextAreaFormFieldProps) => {
  const { control: controlContext } = useFormContext()

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      onSubmit?.()
    }
  }

  return (
    <Controller
      control={control ?? controlContext}
      {...rest}
      render={({ field, fieldState: { error, invalid } }) => {
        return (
          <FormControl fullWidth error={invalid}>
            <TextField
              {...field}
              onKeyDown={handleKeyDown}
              label={label}
              multiline
              helperText={error ? error.message : null}
              error={invalid}
              {...textFieldProps}
            />
          </FormControl>
        )
      }}
    />
  )
}

export default TextAreaFormField
