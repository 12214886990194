import type { SVGProps } from 'react'
const SvgIconAddNote = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M10 2.5h3.5c1.4 0 2.1 0 2.635.273a2.5 2.5 0 0 1 1.093 1.092C17.5 4.4 17.5 5.1 17.5 6.5v5.012c0 .734 0 1.1-.083 1.446a3 3 0 0 1-.359.867c-.185.303-.446.564-.967 1.085l-1.181 1.18c-.521.522-.782.783-1.085.968a3 3 0 0 1-.867.36c-.345.082-.712.082-1.446.082H6.5c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.092-1.093C2.5 15.6 2.5 14.9 2.5 13.5V10m1.667-3.333v-5m-2.5 2.5h5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M12 17.5V16c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.092C13.9 12 14.6 12 16 12h1.5"
    />
  </svg>
)
export default SvgIconAddNote
