import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import * as Sentry from '@sentry/react'
import ENV from '@configs/env'

if (['prod', 'staging'].includes(ENV.ENV)) {
  Sentry.init({
    dsn: 'https://f29309e0b71c46c0067afeedc5d6add0@o4507047275003904.ingest.us.sentry.io/4508382269538304',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'], // ["localhost", /^https:\/\/yourserver\.io\/api/]
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: ENV.ENV,
  })
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
