import { SxProps } from '@mui/material'
import { hiddenScrollBar } from '@styles/theme/sx/common'

export const mapSidebarContainerWidth = 354

export const container = (isCollapse: boolean, isOnly?: boolean): SxProps => ({
  height: 'calc(100vh - 64px)',
  width: mapSidebarContainerWidth,
  boxShadow: isOnly ? '0px 0px 12px rgba(0, 0, 0, 0.12)' : 'none',
  borderRadius: 8,
  zIndex: 1,
  position: 'absolute',
  top: 32,
  left: isCollapse ? -(mapSidebarContainerWidth + 32) : 32,
  transition: 'left 0.8s ease',
})

export const header: SxProps = {
  position: 'relative',
  borderTopRightRadius: 8,
  borderTopLeftRadius: 8,
  padding: '12px 18px',
  color: 'grey.50',
  width: '100%',
  height: 60,
  backgroundColor: 'blue.800',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export const itemFirst: SxProps = {
  color: 'grey.800',
  backgroundColor: 'white',
  width: '100%',
  borderBottomRightRadius: 8,
  borderBottomLeftRadius: 8,
  overflowY: 'auto',
  flex: 1,
  ...hiddenScrollBar,
}

export const openButton = (isCollapse: boolean): SxProps => ({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  color: 'white',
  position: 'absolute',
  right: -86,
  paddingY: '18px',
  backgroundColor: 'blue.800',
  visibility: isCollapse ? 'visible' : 'hidden',
  opacity: 0,
  animation: isCollapse ? 'fadeIn 0.1s ease-out 0.5s forwards' : 'none',
  '&:hover': {
    backgroundColor: 'blue.600',
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 1,
    },
  },
})
