import { ButtonProps, Button } from '@components/atom'
import { styled } from '@mui/material/styles'

export const ActionButtonStyled = styled(Button)<ButtonProps & { selected: boolean }>(({ theme, selected }) => ({
  padding: '14px 16px 0px 16px',
  borderRadius: 8,
  borderColor: theme.palette.divider,
  backgroundColor: selected ? theme.palette.green[100] : 'white',
  color: selected ? theme.palette.green[700] : theme.palette.grey[700],
  '&:hover': {
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.grey[200],
  },
}))
