import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { forwardRef } from 'react'

export interface CloseButtonProps extends IconButtonProps {
  onClick?: () => void
  iconProps?: any
}

const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>((props, ref) => {
  const { onClick, iconProps = {}, ...rest } = props

  return (
    <IconButton disableRipple ref={ref} onClick={onClick} aria-label="close" {...rest}>
      <CloseRoundedIcon {...iconProps} />
    </IconButton>
  )
})

export default CloseButton
