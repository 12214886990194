export interface LabelResponse {
  id: string
  name: string
  code: string
  description: string | null
  is_category?: boolean
  is_master: boolean
  parent_label_id?: string | null
  label_set_id?: string
  project_id: string | null
  created_at?: string
  updated_at?: string | null
  deleted_at?: string | null
}

export class LabelDto {
  id: string
  name: string
  code: string
  description: string | null
  isCategory?: boolean
  isMaster: boolean
  parentLabelId?: string | null
  labelSetId?: string
  projectId: string | null
  createdAt?: string
  updatedAt?: string | null
  deletedAt?: string | null
  labelSet?: LabelSet

  constructor(response: LabelResponse, labelSet?: LabelSet) {
    this.id = response.id
    this.name = response.name
    this.code = response.code
    this.description = response.description
    this.isCategory = response.is_category
    this.isMaster = response.is_master
    this.parentLabelId = response.parent_label_id
    this.labelSetId = response.label_set_id
    this.projectId = response.project_id
    this.createdAt = response.created_at
    this.updatedAt = response.updated_at
    this.deletedAt = response.deleted_at
    this.labelSet = labelSet
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      description: this.description,
      isCategory: this.isCategory,
      isMaster: this.isMaster,
      parentLabelId: this.parentLabelId,
      labelSetId: this.labelSetId,
      projectId: this.projectId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
      labelSet: this.labelSet,
    }
  }
}

export interface LabelSetResponse {
  id: string
  name: string
  created_at: string
  updated_at: string
  task_id: string
  labels: LabelResponse[]
}

export class LabelSet {
  id: string
  name: string
  createdAt: string
  updatedAt: string
  taskId: string
  labels: LabelDto[]

  constructor(response: LabelSetResponse) {
    this.id = response.id
    this.name = response.name
    this.createdAt = response.created_at
    this.updatedAt = response.updated_at
    this.taskId = response.task_id
    this.labels = response.labels.map(label => new LabelDto(label, new LabelSet({ ...response, labels: [] })))
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      taskId: this.taskId,
      labels: this.labels.map(label => label.toJSON()),
    }
  }
}

export type TRequestInsertCustomLabel = {
  id: string
  name: string
  code: string
  description: string | null
  project_id: string | null
  label_set_id: string | null
}

export type TUpdateExistCustomLabel = {
  payload: { name: string; code: string }
  id: string
}
export type TDeleteCustomLabel = {
  id: string
}

export interface LabelSearchResponse {
  annotation_id: string
  confidence_rating: number

  label_name: string

  media_id: string

  observation_id: string
  observation_key: string

  checkpoint_id: string
  checkpoint_key: string
}

export class LabelSearchDto {
  annotationId: string
  confidenceRating: number

  labelName: string

  mediaId: string

  observationId: string
  observationKey: string

  checkpointId: string
  checkpointKey: string

  constructor(data: LabelSearchResponse) {
    this.annotationId = data.annotation_id
    this.confidenceRating = data.confidence_rating

    this.labelName = data.label_name

    this.mediaId = data.media_id

    this.observationId = data.observation_id
    this.observationKey = data.observation_key

    this.checkpointId = data.checkpoint_id
    this.checkpointKey = data.checkpoint_key
  }
}
