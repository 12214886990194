import { ICheckpointLocation } from '@models/checkpoint.model'
import { CheckpointLocationGeoJSONDtoResponse } from '@models/checkpointGeoJon.model'
import { IBaseResponse } from '@models/common.model'
import supabaseClient, { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'
import { uniqBy } from 'lodash'

export interface CheckpointsResponse {
  data: any[] | null
  error: any
}

export class CheckpointService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  fetchCheckpointsByMissionId = async (id: string): Promise<any> => {
    try {
      const response = await supabaseClient
        .from('checkpoint_locations')
        .select('id, ST_AsGeoJSON(single_point_geom) as geom')
        .eq('mission_id', id)
      if (response.error) {
        return { data: null, error: response?.error }
      }
      return { data: response.data as any, error: response.error }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  fetchCheckpointLocationGeoJSON = async (missionId: string): Promise<IBaseResponse<CheckpointLocationGeoJSONDtoResponse>> => {
    if (!missionId) return { data: null, error: 'id not exist' }
    try {
      const response: any = await supabaseClient
        .from('checkpoint_locations')
        .select(
          `id, 
        single_point_geom, 
        mission_id, 
        project_id, task_list:tasks(*)`,
        )
        .eq('mission_id', missionId)
        .geojson()
      if (response.error) {
        return { data: null, error: response?.error }
      }
      const checkpoints = response.data as CheckpointLocationGeoJSONDtoResponse
      return { data: checkpoints, error: null }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  fetchCheckpointsAcrossProject = async ({
    projectId,
    startDate,
    endDate,
  }: {
    projectId: string
    startDate?: string
    endDate?: string
  }): Promise<IBaseResponse<ICheckpointLocation[]>> => {
    const params: { project_id_to_filter: string; start_date?: string; end_date?: string } = {
      project_id_to_filter: projectId,
      start_date: startDate,
      end_date: endDate,
    }
    const { data, error } = await this.client.rpc('get_checkpoints_with_observations_by_project', params)
    if (error) {
      return { data: null, error: error }
    }
    const uniqueCheckpoints = uniqBy(
      data.map((item: any) => {
        item.geojson = JSON.parse(item.geom)
        return { ...item }
      }),
      'checkpoint_id',
    )
    return { data: this.toCamelCase(uniqueCheckpoints), error: null }
  }

  fetchCheckpointsBySurveyId = async ({
    startDate,
    endDate,
    surveyId,
  }: {
    startDate?: string
    endDate?: string
    surveyId: string
  }): Promise<IBaseResponse<ICheckpointLocation[]>> => {
    if (!surveyId) {
      throw new Error('SurveyId are required parameters.')
    }

    const params: {
      survey_id_to_filter: string
      start_date_to_filter?: string
      end_date_to_filter?: string
    } = {
      survey_id_to_filter: surveyId,
    }

    if (startDate) {
      params.start_date_to_filter = startDate
    }
    if (endDate) {
      params.end_date_to_filter = endDate
    }

    const { data, error } = await this.client.rpc('get_checkpoints_with_observations_by_survey', params)
    if (error) {
      console.error('Error fetching observations:', error)
      return { data: null, error: error }
    }

    const uniqueCheckpoints = uniqBy(
      data.map((item: any) => {
        item.geojson = JSON.parse(item.geom)
        return { ...item }
      }),
      'checkpoint_id',
    )
    return { data: this.toCamelCase(uniqueCheckpoints), error: null }
  }
}

// Singleton pattern
const checkpointService = new CheckpointService()

export default checkpointService
