import useMozaicSnackbar from '@hooks/useMozaicSnackbar/useMozaicSnackbar.hook'
import labelService from '@services/LabelService/LabelService'
import { useMutation } from '@tanstack/react-query'
import queryClient from '@utils/queryClient';

export const useCreateLabel = () => {
  const { enqueueErrorSnackbar } = useMozaicSnackbar()
  const mutation = useMutation({
    mutationFn: labelService.insertNewCustomLabel,
    mutationKey: ['labels'],
    onError: error => {
      enqueueErrorSnackbar(error.message)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-labels-list'],
      });
    },
  })

  const editMutation = useMutation({
    mutationFn: labelService.updateExistCustomLabel,
    onError: error => {
      enqueueErrorSnackbar(error.message)
    },
  })

  const removeMutation = useMutation({
    mutationFn: labelService.removeCustomLabel,
    onError: error => {
      enqueueErrorSnackbar(error.message)
    },
  })

  return {
    ...mutation,
    createLabelAsync: mutation.mutateAsync,
    isLoading: mutation.isPending,
    updateLabelAsync: editMutation.mutateAsync,
    isLoadingUpdate: editMutation.isPending,
    removeLabelAsync: removeMutation.mutateAsync,
    isLoadingRemove: removeMutation.isPending,
  }
}

export default useCreateLabel
