import type { SVGProps } from 'react'
const SvgIconNote = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 12 12" {...props}>
    <g clipPath="url(#icon-note_svg__a)">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
        d="M5.5 2H3.9c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.7c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.656c.32.163.74.163 1.581.163h3.7c.84 0 1.26 0 1.581-.164a1.5 1.5 0 0 0 .656-.655C10 9.361 10 8.941 10 8.1V6.5m-3.5 2h-3m4-2h-4m6.56-4.56A1.5 1.5 0 1 1 7.94 4.06a1.5 1.5 0 0 1 2.122-2.12"
      />
    </g>
    <defs>
      <clipPath id="icon-note_svg__a">
        <path fill="#fff" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgIconNote
