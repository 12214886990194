import { IBaseResponse, IPostResponse } from '@models/common.model'
import {
  LabelDto,
  TDeleteCustomLabel,
  TRequestInsertCustomLabel,
  TUpdateExistCustomLabel,
  LabelSearchDto,
  LabelSearchResponse,
} from '@models/label.model'
import supabaseClient, { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'
import _ from 'lodash'

export class LabelService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  searchLabels = async ({
    searchValue = '',
    labelSetId,
    projectId,
  }: {
    searchValue: string
    labelSetId?: string | null
    projectId: string
  }): Promise<IBaseResponse<LabelDto[]>> => {
    try {
      let baseQuery = supabaseClient
        .from('labels')
        .select()
        .or(`is_master.eq.true,project_id.eq.${projectId}`)
        .order('is_master', { ascending: true })
        .order('code', { ascending: true })
        .range(0, 50)

      if (labelSetId !== undefined) {
        baseQuery = labelSetId === null ? baseQuery.is('label_set_id', null) : baseQuery.eq('label_set_id', labelSetId)
      }

      const response = !searchValue ? await baseQuery : await baseQuery.or(`code.ilike.${searchValue}%,name.ilike.${searchValue}%`)

      if (!response || response.error) {
        return { data: null, error: response?.error }
      }
      const labels = response.data.map(item => new LabelDto(item))
      return { data: labels, error: null }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  fetchCustomLabels = async ({ projectId }: { projectId: string }): Promise<IBaseResponse<LabelDto[]>> => {
    try {
      const response = await supabaseClient
        .from('labels')
        .select()
        .eq('is_master', false)
        .filter('project_id', 'eq', projectId)
        .order('created_at', { ascending: false })
        .range(0, 100)

      if (!response || response.error) {
        return { data: null, error: response?.error }
      }
      const labels = response.data.map(item => new LabelDto(item))
      return { data: labels, error: null }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  insertNewCustomLabel = async (request: TRequestInsertCustomLabel): Promise<IPostResponse<LabelDto>> => {
    try {
      let response = await this.client
        .from('labels')
        .insert({ ...request, is_master: false })
        .select('*')
      if (response.error) {
        return { data: null, success: false, error: response?.error }
      }
      return { data: new LabelDto(_.first(response.data)), success: true, error: null }
    } catch (error: any) {
      return { data: null, success: false, error: error }
    }
  }
  updateExistCustomLabel = async (request: TUpdateExistCustomLabel): Promise<IPostResponse<LabelDto>> => {
    try {
      let response = await this.client.from('labels').update(request.payload).eq('id', request.id).select('*')
      if (response.error) {
        return { data: null, success: false, error: response?.error }
      }
      return { data: new LabelDto(_.first(response.data)), success: true, error: null }
    } catch (error: any) {
      return { data: null, success: false, error: error }
    }
  }
  removeCustomLabel = async (request: TDeleteCustomLabel): Promise<IPostResponse<LabelDto>> => {
    try {
      let response = await this.client.from('labels').delete().eq('id', request.id).select('*')
      if (response.error) {
        return { data: null, success: false, error: response?.error }
      }
      return { data: new LabelDto(_.first(response.data)), success: true, error: null }
    } catch (error: any) {
      return { data: null, success: false, error: error }
    }
  }

  fetchLabelsBySurveyId = async ({ surveyId }: { surveyId: string }): Promise<IBaseResponse<LabelSearchDto[]>> => {
    try {
      let response = await this.client.rpc('get_annotation_data_by_survey', {
        survey_id_to_filter: surveyId,
      })
      if (!response || response.error) {
        return { data: null, error: response?.error }
      }
      const labels = response.data.map((item: LabelSearchResponse) => new LabelSearchDto(item))
      return { data: labels, error: null }
    } catch (error: any) {
      return { data: null, error: error }
    }
  }
}

// Singleton pattern
const labelService = new LabelService()

export default labelService
