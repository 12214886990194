import { useSearchParams } from 'react-router-dom'

export type URLParamKey = 'projectId' | 'surveyId' | 'checkpointId' | 'observationId' | 'mediaId' | 'lng' | 'lat' | 'zoom'

/**
 * This hook use to get params from url, provided function to update params
 */
export const useAppParams = () => {
  //http://localhost:3000/checkpoint-overview?taskId=0d4a2bfc-053a-4dbb-bf1f-763275a8262e&checkpointId=f3fc1398-cd7d-41a7-805b-712df8fde57e&projectId=5f76473b-c151-4b53-90d7-a69a784c7eaf
  const [searchParams, setSearchParams] = useSearchParams()

  const projectId = searchParams.get('projectId') ?? ''
  const reportId = searchParams.get('reportId') ?? ''
  const surveyId = searchParams.get('surveyId') ?? ''
  const checkpointId = searchParams.get('checkpointId') ?? ''
  const observationId = searchParams.get('observationId') ?? ''
  const mediaId = searchParams.get('mediaId') ?? ''

  const lngParam = searchParams.get('lng')
  const latParam = searchParams.get('lat')
  const zoomParam = searchParams.get('zoom')

  const updateUrlParam = (param: URLParamKey, value: string) => {
    searchParams.set(param, value)
    setSearchParams(searchParams)
  }

  return {
    projectId,
    reportId,
    surveyId,
    checkpointId,
    observationId,
    mediaId,
    lngParam: lngParam ? Number(lngParam) : undefined,
    latParam: latParam ? Number(latParam) : undefined,
    zoomParam: zoomParam ? Number(zoomParam) : undefined,
    updateUrlParam,
  }
}

export default useAppParams
