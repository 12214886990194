enum EMetricState {
  Outstanding = 'outstanding',
  ClosedInComplete = 'closed_incomplete',
  ClosedCompleted = 'closed_completed',
}

export interface MetricResponse {
  id: number
  created_at?: string
  objective: string
  project_id?: string
  state: string
  deleted_at?: string
}

export class MetricDto {
  createdAt?: string
  id: number
  objective: string
  state: string
  projectId: string
  deletedAt?: string

  constructor(response: MetricResponse) {
    this.objective = response.objective
    this.deletedAt = response.deleted_at
    this.id = response.id
    this.state = response.state as EMetricState
    this.projectId = response.project_id ?? ''
    this.createdAt = response.created_at
  }

  toJSON() {
    return {
      createdAt: this.createdAt,
      objective: this.objective,
      id: this.id,
      state: this.state,
      projectId: this.projectId,
      deletedAt: this.deletedAt,
    }
  }
}
