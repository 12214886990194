interface TileLayerResponse {
  id: string
  bucket_id: string
  folder_path: string
  display_name: string
  project_id: string
}

export class TileLayerDto {
  id: string
  bucketId: string
  folderPath: string
  displayName: string
  projectId: string

  constructor(response: TileLayerResponse) {
    this.id = response.id
    this.bucketId = response.bucket_id
    this.folderPath = response.folder_path
    this.displayName = response.display_name
    this.projectId = response.project_id
  }
}
