import React, { createContext, ReactNode, useContext, useState } from 'react'
export enum ModalID {
  MANAGE_LABEL = 'MANAGE_LABEL',
  CONFIRMATION = 'CONFIRMATION',
  CREATE_SURVEY_SUGGESTION = 'CREATE_SURVEY_SUGGESTION',
}
interface ModalContextType {
  openModal: (id: ModalID, content: ReactNode) => void
  closeModal: (id: ModalID) => void
}

interface ModalState {
  [key: string]: ReactNode | null
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modalState, setModalState] = useState<ModalState>({})

  const openModal = (id: ModalID, content: ReactNode) => {
    setModalState(prevState => ({ ...prevState, [id]: content }))
  }

  const closeModal = (id: ModalID) => {
    setModalState(prevState => ({ ...prevState, [id]: null }))
  }

  const ModalRenderer = () => (
    <>{Object.keys(modalState).map(id => modalState[id] && <React.Fragment key={id}>{modalState[id]}</React.Fragment>)}</>
  )

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <ModalRenderer />
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}
