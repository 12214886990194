import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material'

const MuiTableRow: {
  defaultProps: ComponentsProps['MuiTableRow']
  styleOverrides: ComponentsOverrides<Theme>['MuiTableRow']
} = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      // '&:last-child td, &:last-child th': { border: 0 },
      '&:hover': {
        backgroundColor: theme.palette.grey['50'],
      },
      cursor: 'pointer',
    }),
  },
}

export default MuiTableRow
