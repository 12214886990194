import { Typography as MuiTypography, TypographyProps as TypographyLibProps, useTheme } from '@mui/material'
import { forwardRef } from 'react'

export type TypographyProps = {
  children?: React.ReactNode
  disabled?: boolean
  size:
    | 'display-2xl'
    | 'display-xl'
    | 'display-lg'
    | 'display-md'
    | 'display-sm'
    | 'display-xs'
    | 'text-xl'
    | 'text-lg'
    | 'text-md'
    | 'text-sm'
    | 'text-xs'
  weight: 'regular' | 'medium' | 'semibold' | 'bold'
  ellipsis?: boolean
  maxlines?: number
} & TypographyLibProps &
  any

const Typography = forwardRef<TypographyProps, TypographyProps>(
  ({ children, size = 'text-sm', weight = 'regular', disabled, ellipsis = false, maxlines = 2, ...rest }, ref) => {
    const { palette } = useTheme()
    const textColor = disabled ? palette.grey[400] : 'inherit'
    return (
      <MuiTypography ref={ref} color={textColor} {...rest}>
        {children}
      </MuiTypography>
    )
  },
)

export default Typography
