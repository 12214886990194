import { create } from 'zustand'
import { AuthAction, IUserInfo, AuthState } from './authStore.type'
import { mountStoreDevtool } from 'simple-zustand-devtools'

export const useAuthStore = create<AuthState & AuthAction>(set => ({
  authToken: {},
  updateUserInfo: (payload?: IUserInfo) => set(() => ({ user: payload })),
}))

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('AuthStore', useAuthStore)
}

export default useAuthStore
