import { Box, SxProps } from '@mui/material'
import { SVGProps } from 'react'

export type IconProps = {
  Icon?: (props: SVGProps<SVGSVGElement>) => React.ReactNode
  color?: React.CSSProperties['color']
  width?: React.CSSProperties['width']
  height?: React.CSSProperties['height']
  className?: string
  sx?: SxProps
}

const Icons = ({ Icon, width = 24, height = 24, color = '#475467', ...rest }: IconProps) => {
  return (
    <Box display="flex" width="fit-content" justifyContent="start" alignItems="center" component="span" color={color} {...rest}>
      {Icon ? <Icon {...rest} width={width} height={height} /> : null}
    </Box>
  )
}

export default Icons
