import { HStack, VStack } from '@components/atom'
import { CheckpointOverviewProvider } from '@context/CheckpointOverviewContext'
import { LabelingProvider } from '@context/LabelingContext'
import React from 'react'
import * as styles from './CheckPointOverviewLayout.styles'
import { ModalProvider } from '@context/ModalContext'

export type CheckPointOverviewLayoutProps = {
  TaskList: React.ReactNode
  MiniMap: React.ReactNode
  AnnotationList: React.ReactNode
  LabelingTool: React.ReactNode
  Carousel: React.ReactNode
  SidebarOverview: React.ReactNode
}

export const CheckPointOverviewLayout = ({ children }: any) => {
  return (
    <CheckpointOverviewProvider>
      <LabelingProvider>
        <ModalProvider>{children}</ModalProvider>
      </LabelingProvider>
    </CheckpointOverviewProvider>
  )
}

const Content = ({ LabelingTool, TaskList, MiniMap, AnnotationList, Carousel, SidebarOverview }: CheckPointOverviewLayoutProps) => {
  return (
    <HStack gap={2} sx={styles.layout}>
      <VStack sx={styles.toolBar} gap={2}>
        {SidebarOverview}
        {TaskList}
        {MiniMap}
        {AnnotationList}
      </VStack>
      <VStack gap={2} sx={styles.labeling}>
        {LabelingTool}
        {Carousel}
      </VStack>
    </HStack>
  )
}

CheckPointOverviewLayout.Content = Content

export default CheckPointOverviewLayout
