import tinycolor from 'tinycolor2'
const baseColor = '#10B981'

const MozaicEarthGreen = {
  50: tinycolor(baseColor).lighten(40).toHexString(),
  100: '#D1FAE5',
  200: tinycolor(baseColor).lighten(20).toHexString(),
  300: tinycolor(baseColor).lighten(10).toHexString(),
  400: tinycolor(baseColor).lighten(5).toHexString(),
  500: baseColor,
  600: tinycolor(baseColor).darken(5).toHexString(),
  700: '#047857',
  800: tinycolor(baseColor).darken(15).toHexString(),
  900: tinycolor(baseColor).darken(20).toHexString(),
  A100: tinycolor(baseColor).lighten(50).toHexString(),
  A200: tinycolor(baseColor).lighten(40).toHexString(),
  A400: tinycolor(baseColor).lighten(30).toHexString(),
  A700: tinycolor(baseColor).lighten(20).toHexString(),
}

export default MozaicEarthGreen
