import maplibregl from 'maplibre-gl'
import { bbox } from '@turf/turf'
import { mapSidebarContainerWidth } from '@components/molecule/MapsSidebar/MapsSidebar.styles'

export const calculateBoundsAndCenter = (coordinates: number[][]) => {
  let minLng = coordinates[0][0]
  let maxLng = coordinates[0][0]
  let minLat = coordinates[0][1]
  let maxLat = coordinates[0][1]

  coordinates.forEach(coord => {
    if (coord[0] < minLng) minLng = coord[0]
    if (coord[0] > maxLng) maxLng = coord[0]
    if (coord[1] < minLat) minLat = coord[1]
    if (coord[1] > maxLat) maxLat = coord[1]
  })

  const bounds = new maplibregl.LngLatBounds([minLng, minLat], [maxLng, maxLat])
  const center = [(minLng + maxLng) / 2, (minLat + maxLat) / 2]

  return { bounds, center }
}

export const calculateBoundsShowAllPolygon = (coordinatesList: number[][][]) => {
  let minLng = coordinatesList[0][0][0]
  let maxLng = coordinatesList[0][0][0]
  let minLat = coordinatesList[0][0][1]
  let maxLat = coordinatesList[0][0][1]

  coordinatesList.forEach(coordinates => {
    coordinates.forEach(coord => {
      if (coord[0] < minLng) minLng = coord[0]
      if (coord[0] > maxLng) maxLng = coord[0]
      if (coord[1] < minLat) minLat = coord[1]
      if (coord[1] > maxLat) maxLat = coord[1]
    })
  })

  return new maplibregl.LngLatBounds([minLng, minLat], [maxLng, maxLat])
}

export const calculateCenterFromGeoJson = (geoJson: GeoJSON.FeatureCollection): maplibregl.LngLatLike => {
  let minLng = Infinity
  let maxLng = -Infinity
  let minLat = Infinity
  let maxLat = -Infinity

  geoJson.features.forEach(feature => {
    if (feature.geometry.type === 'Point') {
      const [lng, lat] = feature.geometry.coordinates
      if (lng < minLng) minLng = lng
      if (lng > maxLng) maxLng = lng
      if (lat < minLat) minLat = lat
      if (lat > maxLat) maxLat = lat
    } else if (feature.geometry.type === 'LineString' || feature.geometry.type === 'MultiPoint') {
      feature.geometry.coordinates.forEach(coord => {
        const [lng, lat] = coord
        if (lng < minLng) minLng = lng
        if (lng > maxLng) maxLng = lng
        if (lat < minLat) minLat = lat
        if (lat > maxLat) maxLat = lat
      })
    } else if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiLineString') {
      feature.geometry.coordinates.forEach(ring => {
        ring.forEach(coord => {
          const [lng, lat] = coord
          if (lng < minLng) minLng = lng
          if (lng > maxLng) maxLng = lng
          if (lat < minLat) minLat = lat
          if (lat > maxLat) maxLat = lat
        })
      })
    } else if (feature.geometry.type === 'MultiPolygon') {
      feature.geometry.coordinates.forEach(polygon => {
        polygon.forEach(ring => {
          ring.forEach(coord => {
            const [lng, lat] = coord
            if (lng < minLng) minLng = lng
            if (lng > maxLng) maxLng = lng
            if (lat < minLat) minLat = lat
            if (lat > maxLat) maxLat = lat
          })
        })
      })
    }
  })

  const centerLng = (minLng + maxLng) / 2
  const centerLat = (minLat + maxLat) / 2

  return [centerLng, centerLat] as maplibregl.LngLatLike
}

/**
 * Calculate maxBounds for MapLibre based on a list of features or a GeoJSON object.
 * @param {Feature[] | FeatureCollection | GeoJSON} features - List of features or a GeoJSON object.
 * @param {number} padding - Padding to be added to the bounding box.
 * @returns {maplibregl.LngLatBoundsLike} - The calculated maxBounds with padding.
 */
export const calculateMaxBounds = (geoJson: any, padding: number = 0): maplibregl.LngLatBoundsLike => {
  const [minLng, minLat, maxLng, maxLat] = bbox(geoJson)

  // Adjust the bounding box by the padding amount
  const paddedMinLng = minLng - padding
  const paddedMinLat = minLat - padding
  const paddedMaxLng = maxLng + padding
  const paddedMaxLat = maxLat + padding

  return [
    [paddedMinLng, paddedMinLat],
    [paddedMaxLng, paddedMaxLat],
  ]
}

export const zoomToCenterFeature = (map: any, data: any) => {
  const transformedData = transformMultiPolygonToPolygon(data)
  // TODO: Work out multiline strings conversion? This function
  // is needed to zoom and allow users to click on the item
  const coordinates = transformedData?.features.map((feature: any) => {
    if (feature?.geometry.type === 'LineString') {
      return feature?.geometry.coordinates
    } else {
      // Handle other geometry types
      return feature?.geometry.coordinates[0]
    }
  })
  const bounds = calculateBoundsShowAllPolygon(coordinates)
  map?.fitBounds(bounds, {
    duration: 500,
    animate: false,
    padding: { top: 60, right: 60, bottom: 60, left: mapSidebarContainerWidth },
  })
}

export const getTilesLayerId = (sourceId: string) => {
  return {
    tilesLayerId: `${sourceId}.layer`,
  }
}

export const getPolygonLayerId = (sourceId: string) => {
  return {
    polygonLayerId: `${sourceId}.polygon`,
    outlinedPolygonLayerId: `${sourceId}.polygon_outlined`,
    lineStringLayerId: `${sourceId}.line`,
    symbolLayerId: `${sourceId}.symbol`,
  }
}

export const getCheckpointLayerIds = (sourceId: string) => {
  return {
    unlinkedLayerId: `${sourceId}-unlinked-layer`,
    linkedLayerId: `${sourceId}-linked-layer`,
    selectedLayerId: `${sourceId}-selected-layer`,
  }
}

export const transformMultiPolygonToPolygon = (geojson: any): any => {
  const transformedFeatures = geojson.features.flatMap((feature: any) => {
    if (feature.geometry.type === 'MultiPolygon') {
      return feature.geometry.coordinates.map((coords: any, index: any) => ({
        ...feature,
        geometry: {
          type: 'Polygon',
          coordinates: coords,
        },
        id: `${feature.id}-${index}`,
      }))
    }
    return feature
  })

  return {
    ...geojson,
    features: transformedFeatures,
  }
}

export const fetchCheckPointImage = async (imgUrl: string): Promise<HTMLImageElement> => {
  const response = await fetch(imgUrl)
  if (!response.ok) throw new Error('Network response was not ok')

  const blob = await response.blob()

  const image = new Image()
  image.src = URL.createObjectURL(blob)

  return image
}
