import { SxProps, colors } from '@mui/material'

export const textFieldForm: SxProps = {
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
  },
}

export const saveButton: SxProps = {
  alignSelf: 'center',
  fontWeight: 600,
  color: colors.green[700],
  width: 40,
  height: 40,
  borderRadius: '15%',
  boxShadow: 'none',
  transform: 'none',
  backgroundColor: colors.green[50],
  border: `1px solid ${colors.green[700]}`,
  '&:hover': {
    backgroundColor: colors.green[100],
    boxShadow: 'none',
    transform: 'none',
  },
}
