import { Box, BoxProps, Popover as MuiPopover, PopoverProps as MuiPopoverProps } from '@mui/material'
import * as styles from './Popover.style'

export type PopoverProps = {
  children: React.ReactNode
  contentProps?: BoxProps
} & MuiPopoverProps

export const Popover = ({ children, contentProps = {}, ...rest }: PopoverProps) => {
  const { sx = {} } = contentProps
  return (
    <MuiPopover
      slotProps={{
        paper: { sx: styles.popoverPaper },
      }}
      {...rest}
    >
      <Box {...contentProps} sx={{ position: 'relative', ...sx }}>
        {children}
      </Box>
    </MuiPopover>
  )
}

export default Popover
