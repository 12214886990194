import React, { memo } from 'react'
import Button, { ButtonProps } from '../Button'
import Stack from '../Stack'
import { PopoverActionsStyled } from './Popover.style'
import { SxProps } from '@mui/material'

export type PopoverActionsProps = {
  buttons: ButtonProps[],
  sx?: SxProps
}

const PopoverActions: React.FC<PopoverActionsProps> = memo(({ buttons = [], ...rest }) => {
  return (
    <PopoverActionsStyled {...rest}>
      <Stack spacing={1} direction="row" width="100%" justifyContent="end">
        {buttons.map((buttonProps, index) => (
          <Button {...buttonProps} key={index} />
        ))}
      </Stack>
    </PopoverActionsStyled>
  )
})

export default PopoverActions
