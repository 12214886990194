import { Stack } from '@components/atom'
import TextField, { TextFieldProps } from '@components/atom/TextField'
import { FormControl, InputLabel, InputLabelProps } from '@mui/material'
import { Controller, ControllerProps } from 'react-hook-form'

export type TextFormFieldProps = {
  name: string
  label?: string
  hint?: string
  textFieldProps?: TextFieldProps
  inputLabelProps?: InputLabelProps
} & Omit<ControllerProps, 'render'>

const TextFormField = ({ label, textFieldProps = {}, inputLabelProps = {}, control, ...rest }: TextFormFieldProps) => {
  return (
    <Controller
      control={control}
      {...rest}
      render={({ field, fieldState: { error, invalid } }) => {
        return (
          <FormControl fullWidth error={invalid}>
            <Stack direction="row" alignItems="center">
              {label && <InputLabel {...inputLabelProps}>{label}</InputLabel>}
            </Stack>
            <TextField
              {...field}
              label={label}
              helperText={error ? error.message : null}
              error={invalid}
              size="small"
              {...textFieldProps}
            />
          </FormControl>
        )
      }}
    />
  )
}

export default TextFormField
