import { Box } from '@mui/material'
import Typography from '../Typography'
import CloseButton from '../CloseButton/CloseButton'
import * as styles from './Popover.style'

export type PopoverHeaderProps = {
  title?: string
  onClose?: () => void
  addonComponent?: React.ReactNode
  children?: React.ReactNode
}

export const PopoverHeader = ({ title, onClose, children, addonComponent }: PopoverHeaderProps) => {
  return (
    <Box sx={styles.header}>
      <Box sx={styles.addOnComponent}>{addonComponent}</Box>
      <Typography sx={styles.title}>{title ?? children}</Typography>
      {onClose && <CloseButton onClick={onClose} sx={styles.closeButton} iconProps={{ sx: { width: 20, height: 20 } }} />}
    </Box>
  )
}

export default PopoverHeader
