import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledMaps = styled(Box, {
  shouldForwardProp: prop => prop !== 'isFullScreen',
})<BoxProps & { isFullScreen?: boolean }>(({ isFullScreen, width, height }) => ({
  width: isFullScreen ? '100vw' : width + '',
  height: isFullScreen ? '100vh' : height + '',
  '.maplibregl-ctrl-bottom-right': {
    display: 'none',
  },
}))
