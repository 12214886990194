import { List, ListItem, styled } from '@mui/material'

export const StyledList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  listStyleType: 'decimal',
  paddingTop: 0,
}))

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'list-item',
  paddingLeft: 0,
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
  fontSize: '16px',
}))
