export enum ERoutePaths {
  Home = '/',
  Login = '/login',
  Report = '/report',
  Reports = '/reports',
  Projects = '/projects',
  CheckpointOverview = '/checkpoint-overview',
  Map = '/map',
  ListSurvey = '/map/list-survey',
  CreateSurvey = '/map/list-survey/create-survey',
  EditSurvey = '/map/list-survey/edit-survey',
  SurveyDetail = '/map/survey-detail',
  NotFound = '/404',
}

export default ERoutePaths
