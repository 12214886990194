import { Box, Maps } from '@components/atom'
import { useMapContext } from '@context/MapContext/MapContext'
import withMapContext from '@context/MapContext/withMapContext'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const MapsFullScreenLayout = () => {
  const { mapContainer, map } = useMapContext()

  useEffect(() => {
    if (map.current) {
      return () => {
        map.current?.remove()
        map.current = null
      }
    }
  }, [])

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Outlet />
      <Maps ref={mapContainer} isFullScreen />
    </Box>
  )
}

export default withMapContext(MapsFullScreenLayout)
