import ERoutePaths from '@configs/routes/routes.path'
import { useMemoizedFn } from 'ahooks'
import { useNavigate } from 'react-router-dom'

export const useMozaicNavigation = () => {
  const navigate = useNavigate()

  const navigateToHomepage = useMemoizedFn(() => navigate(ERoutePaths.Home))

  const navigateToLoginPage = useMemoizedFn(() =>
    // Prevent users getting stuck of an infinite loop of navigating back to login even though they already have
    // Was seen when a new user was created and attached to nothing
    navigate(ERoutePaths.Login, {
      state: window.location.href.includes(ERoutePaths.Login) ? {} : { redirectUrl: window.location.href },
    }),
  )

  const navigateToProjectsPage = useMemoizedFn(() => navigate(ERoutePaths.Projects))

  return {
    navigate,
    navigateToLoginPage,
    navigateToHomepage,
    navigateToProjectsPage,
  }
}

export default useMozaicNavigation
