import { EMode } from './LabelingImage'

export const adjustCoordinateToOrigin = ({ x, y, transformX, transformY, scale }: any) => {
  const adjustedX = (x - transformX) / scale
  const adjustedY = (y - transformY) / scale
  return [adjustedX, adjustedY]
}

export const adjustOriginToToolCoordinate = ({ x, y, transformX, transformY, scale }: any) => {
  const adjustedX = x * scale + transformX
  const adjustedY = y * scale + transformY
  return [adjustedX, adjustedY]
}

export const getCursorByMode = (mode: EMode) => {
  const modeCursor = {
    [EMode.Cursor]: 'default',
    [EMode.HandFree]: 'grab',
    [EMode.CustomLabel]: 'crosshair',
    [EMode.SpeciesLabel]: 'crosshair',
    [EMode.GenericLabel]: 'crosshair',
    [EMode.Note]: 'context-menu',
  }
  return modeCursor[mode] ?? 'auto'
}
