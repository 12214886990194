import { lazy } from 'react'
import PrivateRoute from '@components/template/PrivateRoute'
import ERoutePaths from './routes.path'

const CheckpointOverview = lazy(() => import('@pages/CheckpointOverviewPage/CheckpointOverviewPage'))

const checkpointRoutes = {
  checkpointOverview: {
    path: ERoutePaths.CheckpointOverview,
    element: (
      <PrivateRoute title="Mozaic Earth Portal | Checkpoint Overview">
        <CheckpointOverview />
      </PrivateRoute>
    ),
  },
}

export default checkpointRoutes
