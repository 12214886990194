import { Box, BoxProps } from '@mui/material'
import * as styles from './Popover.style'

export type PopoverProps = {
  children: React.ReactNode
} & BoxProps

export const PopoverContent = ({ children, sx = {}, ...rest }: PopoverProps) => {
  return (
    <Box sx={{ ...styles.content, ...sx } as any} {...rest}>
      {children}
    </Box>
  )
}

export default PopoverContent
