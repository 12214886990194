import { forwardRef } from 'react'

import { ButtonGroup } from '@components/atom'
import Icons from '@components/atom/Icons'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { useTheme } from '@mui/material'

import Render from '@components/atom/Render/Render'
import { ActionButtonStyled } from './LabelingCollapse.style'

export type LabelingCollapseProps = {
  toggleActions: () => void
  collapseLabel: boolean
}

const LabelingCollapse = forwardRef(({ toggleActions, collapseLabel }: LabelingCollapseProps, _) => {
  const { palette } = useTheme()

  return (
    <ButtonGroup variant="outlined" aria-label="labeling-toolbar">
      <ActionButtonStyled buttonSize="auto" onClick={toggleActions}>
        <Render
          test={collapseLabel}
          fallback={
            <Icons Icon={() => <UnfoldLessIcon sx={{ width: 24, height: 24, transform: 'rotate(90deg)' }} />} color={palette.grey[700]} />
          }
        >
          <Icons Icon={() => <UnfoldMoreIcon sx={{ width: 24, height: 24, transform: 'rotate(90deg)' }} />} color={palette.grey[700]} />
        </Render>
      </ActionButtonStyled>
    </ButtonGroup>
  )
})

export default LabelingCollapse
