import { Button as MuiButton, ButtonProps as MuiButtonProps, SxProps } from '@mui/material'
import Box from '../Box'
import './loading.css'

export type ButtonProps = {
  children?: React.ReactNode
  buttonSize?: 'lg' | 'md' | 'sm' | 'auto' | 'xs'
  loading?: boolean
} & MuiButtonProps

const buttonVariantSize = {
  auto: {
    height: 'auto',
    padding: '16px 24px',
    borderRadius: '10px',
  },
  lg: {
    height: '60px',
    padding: '16px 24px',
    borderRadius: '10px',
  },
  md: {
    height: '44px',
    padding: '10px 18px',
    borderRadius: '10px',
  },
  sm: {
    height: '36px',
    padding: '8px 16px',
    borderRadius: '8px',
  },
  xs: {
    height: '28px',
    padding: '6px 10px',
    borderRadius: '16px',
    fontSize: '12px',
    lineHeight: 1,
  },
}

const Button = ({ children, buttonSize = 'md', sx, loading, startIcon, ...rest }: ButtonProps) => {
  const buttonStyle: SxProps = buttonVariantSize[buttonSize]
  const loadingComponent = <Box component="span" className="loader" />

  return (
    <MuiButton className="mozaic-button" startIcon={loading ? null : startIcon} sx={{ ...buttonStyle, ...sx }} {...rest}>
      {loading ? loadingComponent : children}
    </MuiButton>
  )
}

export default Button
