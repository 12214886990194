import { SxProps } from '@mui/material'
import { hiddenScrollBar } from '@styles/theme/sx/common'

export const labelSearchSidebarContainerWidth = 300

export const container = (expanded: boolean): SxProps => ({
  height: 'calc(100vh - 64px)',
  width: labelSearchSidebarContainerWidth,
  borderRadius: 8,
  zIndex: 2, // Ensure it sits over the additional map layers section if that exists
  position: 'absolute',
  top: 32,
  right: expanded ? 20 : -(labelSearchSidebarContainerWidth + 32),
  transition: 'right 0.8s ease',
})

export const header: SxProps = {
  position: 'relative',
  borderTopRightRadius: 8,
  borderTopLeftRadius: 8,
  padding: '12px 18px',
  color: 'grey.50',
  width: '100%',
  height: 60,
  backgroundColor: 'blue.800',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export const background: SxProps = {
  color: 'grey.800',
  backgroundColor: 'white',
  width: '100%',
  borderBottomRightRadius: 8,
  borderBottomLeftRadius: 8,
  overflowY: 'auto',
  flex: 1,
  ...hiddenScrollBar,
}

export const openButton = (expanded: boolean): SxProps => ({
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  color: 'white',
  position: 'absolute',
  right: labelSearchSidebarContainerWidth + 32,
  paddingY: '18px',
  backgroundColor: 'blue.800',
  visibility: expanded ? 'hidden' : 'visible',
  opacity: 0,
  animation: expanded ? 'none' : 'fadeIn 0.1s ease-out 0.5s forwards',
  '&:hover': {
    backgroundColor: 'blue.600',
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 1,
    },
  },
})

export const title: SxProps = {
  width: '100%',
  padding: '18px',
}
