import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { ProjectState, ProjectActions } from './projectStore.type'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { IProject } from '@models/projects.model'

export const useProjectStore = create<ProjectState & ProjectActions>()(
  persist<ProjectState & ProjectActions>(
    (set, get) => ({
      projects: [],
      projectDetail: undefined,

      setProjects: (projects: IProject[]) => {
        if (projects.length) {
          set({ projects })
        }
      },

      setProjectDetail: (projectId: string) => {
        const { projects } = get()
        if (projectId && projects) {
          const project = projects.find(p => p.id === projectId)
          set({ projectDetail: project || undefined })
        }
      },
    }),
    {
      name: 'project-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ) as any,
)

// Devtool integration
if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('ProjectStore', useProjectStore)
}

export default useProjectStore
