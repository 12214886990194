import { forwardRef } from 'react'
import MuiPaginationItem from '@mui/material/PaginationItem'
import { PaginationItemProps } from '@mui/material/PaginationItem'
import { Typography } from '@components/atom'

const PaginationItem = forwardRef<HTMLDivElement, PaginationItemProps>((props, ref) => {
  return (
    <MuiPaginationItem
      ref={ref}
      slots={{
        previous: () => <Typography>Previous</Typography>,
        next: () => <Typography>Next</Typography>,
      }}
      {...props}
    />
  )
})

export default PaginationItem
