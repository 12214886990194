import '@watergis/maplibre-gl-terradraw/dist/maplibre-gl-terradraw.css'
import maplibregl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { createContext, ReactNode, useContext, useRef, useState } from 'react'
import ENV from '@configs/env'
import supabaseClient from '@utils/supabase/MozaicSupebaseClient'

import useAppParams from '@hooks/useAppParams'
import { CheckpointStates, PolygonVariants } from '@utils/map.utils'
import { MozaicMap } from '@utils/MozaicMap/MozaicMap'

import CPMarkerDisabled from '@assets/images/checkpoint/checkpoint-disabled.png'
import CPMarkerOutstanding from '@assets/images/checkpoint/checkpoint-outstanding.png'
import CPMarkerSelected from '@assets/images/checkpoint/checkpoint-selected.png'

import { DefaultSourceId } from '@utils/map.utils'

import {
  calculateCenterFromGeoJson,
  calculateMaxBounds,
  getCheckpointLayerIds,
  getPolygonLayerId,
  getTilesLayerId,
  zoomToCenterFeature,
} from './MapContext.helper'
import useQueryTileLayersByProjectId from '@hooks/queries/useQueryTileLayersByProjectId/useQueryTileLayersByProjectId.hook'

interface MapContextType {
  map: React.MutableRefObject<maplibregl.Map | null>
  mapContainer: React.MutableRefObject<any>
  editModeEnabled: any
  setEditModeEnabled: any
  selectedTileLayer: string | null
  setSelectedTileLayer: any
}

interface LabelingProviderProps {
  children: ReactNode
}

export type customMapOptions = Omit<maplibregl.MapOptions, 'container'>

const MapContext = createContext<MapContextType | null>(null)

const MapProvider: React.FC<LabelingProviderProps> = ({ children }) => {
  const map = useRef<maplibregl.Map | null>(null)
  const [editModeEnabled, setEditModeEnabled] = useState<boolean>(false)
  const mapContainer = useRef<any>(null)
  const [selectedTileLayer, setSelectedTileLayer] = useState<string | null>(null)

  return (
    <MapContext.Provider value={{ map, mapContainer, editModeEnabled, setEditModeEnabled, selectedTileLayer, setSelectedTileLayer }}>
      {children}
    </MapContext.Provider>
  )
}

const useMapContext = () => {
  const { projectId, lngParam, latParam, zoomParam } = useAppParams()
  const { tileLayers } = useQueryTileLayersByProjectId({ projectId })

  const context = useContext(MapContext)

  if (!context) {
    throw new Error('useMapContext must be used within a MapProvider')
  }

  const { map, mapContainer, editModeEnabled, setEditModeEnabled, selectedTileLayer, setSelectedTileLayer } = context

  const lng = lngParam ?? -2
  const lat = latParam ?? 54
  const initialZoom = zoomParam ?? 5

  const getMapParams = () => {
    const zoom = map.current?.getZoom()
    const center = map.current?.getCenter()
    return {
      lat: center?.lat,
      lng: center?.lng,
      zoom,
    }
  }

  const initMapInstance = (options: customMapOptions = {}, geoJson?: any) => {
    // Return map if it already exists
    if (map.current) return map.current

    let maxBounds
    let center: maplibregl.LngLatLike = [lng, lat]

    if (geoJson) {
      maxBounds = calculateMaxBounds(geoJson, 0.2)
      center = calculateCenterFromGeoJson(geoJson)
    }

    map.current = new MozaicMap({
      container: mapContainer.current,
      center,
      maxBounds,
      zoom: initialZoom,
      ...options,
    }).instance

    return map.current
  }

  const loadCheckpointImages = async () => {
    // Image resources only need to be loaded once
    if (!map.current) return

    if (!map.current.hasImage(CheckpointStates.UNLINKED)) {
      const image = await map.current.loadImage(CPMarkerDisabled)
      if (image && !map.current.hasImage(CheckpointStates.UNLINKED)) map.current.addImage(CheckpointStates.UNLINKED, image.data)
    }
    if (!map.current.hasImage(CheckpointStates.LINKED)) {
      const image = await map.current.loadImage(CPMarkerOutstanding)
      if (image && !map.current.hasImage(CheckpointStates.LINKED)) map.current.addImage(CheckpointStates.LINKED, image.data)
    }
    if (!map.current.hasImage(CheckpointStates.SELECTED)) {
      const image = await map.current.loadImage(CPMarkerSelected)
      if (image && !map.current.hasImage(CheckpointStates.SELECTED)) map.current.addImage(CheckpointStates.SELECTED, image.data)
    }
  }

  const addPolygonsLayer = ({
    sourceId,
    data,
    // autoZoomInOnClick,
    autoZoomCenter = true,
    onClick,
    customPolygonPaint,
    customPolygonOutlinedPaint,
    customLinePaint,
    clearListeners,
  }: {
    sourceId: string
    data: any
    autoZoomInOnClick?: boolean
    autoZoomCenter?: boolean
    onClick?: (e: any, feature: any) => void
    customPolygonPaint?: any
    customPolygonOutlinedPaint?: any
    customLinePaint?: any
    clearListeners?: boolean
  }) => {
    if (!map.current || !data) return

    if (map.current.getSource(sourceId)) {
      ;(map.current.getSource(sourceId) as maplibregl.GeoJSONSource).setData(data)
    } else {
      map.current.addSource(sourceId, {
        type: 'geojson',
        data: data,
      })
    }

    const { lineStringLayerId, outlinedPolygonLayerId, polygonLayerId, symbolLayerId } = getPolygonLayerId(sourceId)

    let referenceLayerId: string | undefined
    map.current.getLayersOrder().forEach(layerId => {
      if (layerId.endsWith('selected-layer') && !referenceLayerId) referenceLayerId = layerId
      if (layerId.endsWith('linked-layer') && !referenceLayerId) referenceLayerId = layerId
      if (layerId.endsWith('unlinked-layer')) referenceLayerId = layerId
    })

    if (!map.current.getLayer(symbolLayerId)) {
      map.current.addLayer(
        {
          id: symbolLayerId,
          type: 'symbol',
          source: sourceId,
          layout: {
            'text-field': ['get', 'plotKey', ['get', 'mapFeatures']],
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-size': 12,
          },
          paint: {
            'text-color': '#000',
            'text-halo-color': '#fff',
            'text-halo-width': 2,
          },
        },
        referenceLayerId,
      ) // Insert the plot layers below the checkpoint layers, if they are already on the map

      map.current.on('mouseenter', symbolLayerId, () => {
        if (map.current) {
          map.current.getCanvas().style.cursor = 'pointer'
        }
      })

      map.current.on('mouseleave', symbolLayerId, e => {
        const activeLayers = []
        if (e.target.getLayer(lineStringLayerId)) activeLayers.push(lineStringLayerId)
        if (e.target.getLayer(polygonLayerId)) activeLayers.push(polygonLayerId)
        if (e.target.getLayer(symbolLayerId)) activeLayers.push(symbolLayerId)

        const features = map.current?.queryRenderedFeatures(e.point, {
          layers: activeLayers,
        })

        if (map.current && !features?.length) {
          map.current.getCanvas().style.cursor = ''
        }
      })
    }

    const lineLayer = map.current.getLayer(lineStringLayerId)
    if (lineLayer) {
      const paint = customLinePaint ?? PolygonVariants.lightGreen.outlined
      Object.keys(paint).forEach(
        key => map.current!.setPaintProperty(lineStringLayerId, key, paint[key]),
        // lineLayer.setPaintProperty(key, paint[key])
      )
    } else {
      map.current.addLayer(
        {
          id: lineStringLayerId,
          type: 'line',
          filter: ['in', '$type', 'LineString'],
          source: sourceId,
          layout: {},
          paint: customLinePaint ?? PolygonVariants.lightGreen.outlined,
        },
        symbolLayerId,
      )

      map.current.on('mouseenter', lineStringLayerId, () => {
        if (map.current) {
          map.current.getCanvas().style.cursor = 'pointer'
        }
      })

      map.current.on('mouseleave', lineStringLayerId, e => {
        const activeLayers = []
        if (e.target.getLayer(lineStringLayerId)) activeLayers.push(lineStringLayerId)
        if (e.target.getLayer(polygonLayerId)) activeLayers.push(polygonLayerId)
        if (e.target.getLayer(symbolLayerId)) activeLayers.push(symbolLayerId)

        const features = map.current?.queryRenderedFeatures(e.point, {
          layers: activeLayers,
        })

        if (map.current && !features?.length) {
          map.current.getCanvas().style.cursor = ''
        }
      })
    }

    const polyOutlineLayer = map.current.getLayer(outlinedPolygonLayerId)
    if (polyOutlineLayer) {
      const paint = customPolygonOutlinedPaint ?? PolygonVariants.blue.outlined
      Object.keys(paint).forEach(
        key => map.current!.setPaintProperty(outlinedPolygonLayerId, key, paint[key]),
        // polyOutlineLayer.setPaintProperty(key, paint[key])
      )
    } else {
      map.current.addLayer(
        {
          id: outlinedPolygonLayerId,
          type: 'line',
          source: sourceId,
          layout: {},
          paint: customPolygonOutlinedPaint ?? PolygonVariants.blue.outlined,
        },
        lineStringLayerId,
      )
    }

    const polyLayer = map.current.getLayer(polygonLayerId)
    if (polyLayer) {
      const paint = customPolygonPaint ?? PolygonVariants.blue.fill
      Object.keys(paint).forEach(
        key => map.current!.setPaintProperty(polygonLayerId, key, paint[key]),
        // polyLayer.setPaintProperty(key, paint[key])
      )
    } else {
      map.current.addLayer(
        {
          id: polygonLayerId,
          type: 'fill',
          filter: ['in', '$type', 'Polygon'],
          source: sourceId,
          layout: {},
          paint: customPolygonPaint ?? PolygonVariants.blue.fill,
        },
        outlinedPolygonLayerId,
      )

      map.current.on('mouseenter', polygonLayerId, () => {
        if (map.current) {
          map.current.getCanvas().style.cursor = 'pointer'
        }
      })

      map.current.on('mouseleave', polygonLayerId, e => {
        const activeLayers = []
        if (e.target.getLayer(lineStringLayerId)) activeLayers.push(lineStringLayerId)
        if (e.target.getLayer(polygonLayerId)) activeLayers.push(polygonLayerId)
        if (e.target.getLayer(symbolLayerId)) activeLayers.push(symbolLayerId)

        const features = map.current?.queryRenderedFeatures(e.point, {
          layers: activeLayers,
        })

        if (map.current && !features?.length) {
          map.current.getCanvas().style.cursor = ''
        }
      })
    }

    if (autoZoomCenter) {
      zoomToCenterFeature(map.current, data)
    }

    // TODO: Tech-Debt
    // On create survey we want to clear event listeners first as otherwise clicking on polygon navigates to it
    if (clearListeners) {
      delete map.current._listeners.click
    }
    if (!!onClick) {
      map.current.on('click', [lineStringLayerId, polygonLayerId, symbolLayerId], e => {
        if (!e.features || e.features.length === 0) return

        // NOTE: If there are multiple features, may need to prioritise which layer to select from
        // Filter out features where properties.disabled is true
        const enabledFeatures = e.features.filter(feature => !feature.properties.disabled)

        // Return if no enabled features are found
        if (enabledFeatures.length === 0) return

        // Pass the first enabled feature to the onClick handler
        onClick?.(e, enabledFeatures[0])
      })
    }
  }

  const removePolygonLayerBySourceId = (sourceId: string) => {
    const { polygonLayerId, outlinedPolygonLayerId, lineStringLayerId, symbolLayerId } = getPolygonLayerId(sourceId)

    if (map.current?.getLayer(polygonLayerId)) map.current?.removeLayer(polygonLayerId)
    if (map.current?.getLayer(outlinedPolygonLayerId)) map.current?.removeLayer(outlinedPolygonLayerId)
    if (map.current?.getLayer(lineStringLayerId)) map.current?.removeLayer(lineStringLayerId)
    if (map.current?.getLayer(symbolLayerId)) map.current?.removeLayer(symbolLayerId)

    if (map.current?.getSource(sourceId)) map.current?.removeSource(sourceId)
  }

  const addCheckPointLayer = async ({
    sourceId,
    data,
    onClick,
  }: {
    sourceId: string
    data: any
    onClick?: (e: any, feature: any) => void
    isMiniMap?: boolean
  }) => {
    if (!map.current || !data) return

    const { unlinkedLayerId, linkedLayerId, selectedLayerId } = getCheckpointLayerIds(sourceId)

    // Update the source data if it already exists
    if (map.current.getSource(sourceId)) {
      ;(map.current.getSource(sourceId) as maplibregl.GeoJSONSource).setData(data)
    } else {
      map.current.addSource(sourceId, {
        type: 'geojson',
        data: data,
      })
    }

    // Add source layers, if needed
    const addSymbolLayer = (layerId: string, filter: any, sourceId: string, onClick?: (e: any, feature: any) => void) => {
      if (!map.current?.getLayer(layerId)) {
        map.current?.addLayer({
          id: layerId,
          type: 'symbol',
          source: sourceId,
          filter,
          layout: {
            'icon-image': [
              'case',
              ['==', ['get', 'selected'], true], // Check if selected is true
              'selected-checkpoint', // Use selected-checkpoint image if selected is true
              ['==', ['get', 'linked'], true], // Check if linked is true
              'linked-checkpoint', // Use linked-checkpoint image if linked is true
              'unlinked-checkpoint', // Otherwise, use the state property
            ],
            'icon-size': 0.8,
            'icon-allow-overlap': true,
            'icon-offset': [0, -24],
          },
        })

        // Add mouse event handlers
        map.current?.on('mouseenter', layerId, () => {
          if (map.current) {
            map.current.getCanvas().style.cursor = 'pointer'
          }
        })

        map.current?.on('mouseleave', layerId, e => {
          const features = map.current?.queryRenderedFeatures(e.point, {
            layers: [unlinkedLayerId, linkedLayerId, selectedLayerId],
          })
          if (map.current && !features?.length) {
            map.current.getCanvas().style.cursor = ''
          }
        })

        if (onClick) {
          map.current?.on('click', layerId, e => {
            if (e.features?.length) {
              onClick(e, e.features[0])
            }
          })
        }
      }
    }

    // Add layers with filters and click handlers
    addSymbolLayer(unlinkedLayerId, ['==', ['get', 'linked'], false], sourceId, onClick)
    addSymbolLayer(linkedLayerId, ['==', ['get', 'linked'], true], sourceId, onClick)
    addSymbolLayer(selectedLayerId, ['==', ['get', 'selected'], true], sourceId, onClick)
  }

  const removeCheckpointLayerBySourceId = (sourceId: string) => {
    const unlinkedLayerId = `${sourceId}-unlinked-layer`
    const linkedLayerId = `${sourceId}-linked-layer`
    const selectedLayerId = `${sourceId}-selected-layer`

    if (map.current?.getLayer(unlinkedLayerId)) map.current?.removeLayer(unlinkedLayerId)
    if (map.current?.getLayer(linkedLayerId)) map.current?.removeLayer(linkedLayerId)
    if (map.current?.getLayer(selectedLayerId)) map.current?.removeLayer(selectedLayerId)

    if (map.current?.getSource(sourceId)) map.current?.removeSource(sourceId)
  }

  const zoomToCheckpointById = (geoJsonData: any, checkpointId: string) => {
    if (!map.current) return

    const checkpoint = geoJsonData.features.find((feature: any) => feature.properties.id === checkpointId)

    if (checkpoint) {
      const coordinates = checkpoint.geometry.coordinates

      map.current.flyTo({
        center: coordinates,
        zoom: 16,
        essential: true,
        duration: 500,
        animate: false,
      })
    }
  }

  const addTilesLayer = async (folderPath: string, bucketId: string, sourceId = 'custom-map-tiles') => {
    if (!map.current) return

    const { tilesLayerId } = getTilesLayerId(sourceId)

    // Check if the layer already exists
    if (map.current.getLayer(tilesLayerId)) {
      return
    }

    const { data } = await supabaseClient.auth.getSession()
    if (!data.session) return
    const token = data.session.access_token

    // Only add the source, transform and error listener once, the first time loadTiles is called.
    if (!map.current.getSource(sourceId)) {
      map.current.addSource(sourceId, {
        type: 'raster',
        tiles: [`${ENV.SUPABASE_URL}/storage/v1/object/authenticated/${bucketId}/${folderPath}/{z}/{x}/{y}.png`],
        tileSize: 256,
        scheme: 'tms',
      })

      map.current.setTransformRequest((url, resourceType) => {
        if (resourceType === 'Tile' && url.includes(bucketId)) {
          return {
            url,
            headers: { Authorization: `Bearer ${token}` },
          }
        }
        return { url }
      })

      // Suppress tiles load errors becauses areas without tiles produced will throw errors regularly
      map.current.on('error', tileErrorListener)
    }

    const layers = map.current.getStyle().layers

    // Function to determine if a layer is a custom layer
    const isCustomLayer = (layer: any) => {
      return layer.id.includes(DefaultSourceId.PlotBoundaries) || layer.id.includes(DefaultSourceId.Checkpoints)
    }

    // Find the first custom layer index
    const firstCustomLayer = layers.find(layer => isCustomLayer(layer))
    const beforeLayerId = firstCustomLayer ? firstCustomLayer.id : undefined

    // Add your tile layer before the first custom layer or at the top if none exist
    map.current.addLayer(
      {
        id: tilesLayerId,
        type: 'raster',
        source: sourceId,
        paint: {
          'raster-opacity': 1,
          'raster-fade-duration': 0,
        },
      },
      beforeLayerId,
    )
  }

  const tileErrorListener = (error: any) => {
    const errorMessage = error.error?.message || ''
    if (errorMessage.includes('400') && errorMessage.includes('tile_layer')) {
      return
    }

    console.error('MapLibre error:', error)
  }

  const removeTilesLayer = (sourceId = 'custom-map-tiles') => {
    if (!map.current) return

    const { tilesLayerId } = getTilesLayerId(sourceId)

    // Remove the layer
    if (map.current.getLayer(tilesLayerId)) {
      map.current.removeLayer(tilesLayerId)
    }
    // Remove the source
    if (map.current.getSource(sourceId)) {
      map.current.removeSource(sourceId)
    }
    // Remove the transform request
    map.current.setTransformRequest((url, _) => {
      return { url }
    })

    // Remove the error listener
    map.current.off('error', tileErrorListener)
  }

  const handleUpdateTileLayer = (folderPath: string | null) => {
    setSelectedTileLayer(folderPath)
  }

  return {
    initMapInstance,
    map,
    mapContainer,
    loadCheckpointImages,
    addPolygonsLayer,
    removePolygonLayerBySourceId,
    addCheckPointLayer,
    zoomToCheckpointById,
    zoomToCenterFeature,
    getMapParams,
    removeCheckpointLayerBySourceId,
    /**
     * Tile layers
     */
    addTilesLayer,
    removeTilesLayer,
    tileLayers,
    selectedTileLayer,
    handleUpdateTileLayer,
    /**
     * Draw tool
     */
    editModeEnabled,
    setEditModeEnabled,
  }
}

export { MapProvider, useMapContext }
