import { Box, Stack, Typography } from '@components/atom'
import Icons from '@components/atom/Icons'
import BrandLogo from '@components/molecule/BrandLogo/BrandLogo'
import MenuSideBar from '@components/molecule/MenuList'
import { Avatar, Divider, IconButton } from '@mui/material'
import useAuthStore from '@stores/Auth/authStore'
import SvgIconLogOut from '../../../icons/IconLogOut'
import { listSideBar } from './Sidebar.config'
import { container, userEmail } from './Sidebar.styles'

export type SidebarProps = {
  onClickLogout: () => void
  logoutLoading: boolean
}

const Sidebar = ({ onClickLogout, logoutLoading }: SidebarProps) => {
  const { user } = useAuthStore()

  return (
    <Box sx={container}>
      <Stack direction="column" padding="32px 16px" gap="24px">
        <BrandLogo />
        <MenuSideBar menuItems={listSideBar.topMenu} />
      </Stack>
      <Stack direction="column" padding="32px 24px 0 24px" gap="24px">
        {/* <MenuSideBar menuItems={listSideBar.bottomMenu} /> */}
        <Box>
          <Divider />
          <Stack padding="16px 0" alignItems="center">
            <Avatar alt="User" src="/path/to/user/avatar" />
            <Box sx={{ ml: 2, flex: 1 }}>
              <Typography size="text-sm" weight="semibold" sx={userEmail}>
                {user?.email}
              </Typography>
            </Box>
            <IconButton onClick={onClickLogout} disabled={logoutLoading}>
              <Icons Icon={SvgIconLogOut} />
            </IconButton>
          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}

export default Sidebar
