import useMozaicNavigation from '@hooks/useMozaicNavigate/useMozaicNavigate.hook'
import useMozaicSnackbar from '@hooks/useMozaicSnackbar/useMozaicSnackbar.hook'
import authService from '@services/AuthService/AuthService'
import { TVerifyOtpResponseSuccess } from '@services/AuthService/AuthService.models'
import useAuthStore from '@stores/Auth/authStore'
import { useMutation } from '@tanstack/react-query'
import { useRef } from 'react'

interface UseAuthenticationProps {
  onSendMailSuccess?: (data: any) => void
  onVerifyOtpSuccess?: () => void
  onSendMailFailed?: (error: Error) => void
  onVerifyOtpFailed?: (error: Error) => void
}

export const useAuthentication = ({
  onSendMailSuccess,
  onVerifyOtpSuccess,
  onSendMailFailed,
  onVerifyOtpFailed,
}: UseAuthenticationProps = {}) => {
  const { navigateToLoginPage } = useMozaicNavigation()
  const { enqueueErrorSnackbar } = useMozaicSnackbar()
  const mail = useRef('')

  const user = useAuthStore(state => state.user)

  const updateUserInfo = useAuthStore(state => state.updateUserInfo)

  const handleLoginSuccess = (res: TVerifyOtpResponseSuccess) => {
    updateUserInfo({ email: res.user?.email, id: res?.user?.id })
    onVerifyOtpSuccess?.()
  }

  const signInMutation = useMutation({
    mutationFn: authService.loginWithEmailOtp,
    onSuccess: (data, email) => {
      mail.current = email
      updateUserInfo(data)
      onSendMailSuccess?.(data)
    },
    onError: onSendMailFailed,
  })

  const verifyOtpMutation = useMutation({
    mutationFn: (otp: string) => authService.verifyOtp({ email: mail.current, otp }),
    onSuccess: data => {
      handleLoginSuccess?.(data)
    },
    onError: onVerifyOtpFailed,
  })

  const onLogoutSuccess = () => {
    updateUserInfo(undefined)
    navigateToLoginPage()
  }

  const logoutMutation = useMutation({
    mutationFn: authService.logout,
    onSuccess: onLogoutSuccess,
  })

  const logout = async ({ type = 'manual' }: { type?: 'auto' | 'manual' } = {}) => {
    await logoutMutation.mutateAsync()
    updateUserInfo(undefined)
    navigateToLoginPage()
    if (type === 'auto') {
      enqueueErrorSnackbar("You've been logged out!")
    }
  }

  const resendOtpMutation = useMutation({
    mutationFn: () => authService.loginWithEmailOtp(mail.current),
  })

  return {
    user,
    /**
     * Verify Otp
     */
    verifyOtpMutation,
    verifyOtpLoading: verifyOtpMutation.isPending,
    /**
     * Send mail, sign in
     */
    signInMutation,
    signInLoading: signInMutation.isPending,
    resendOtpMutation,
    /**
     * Logout
     */
    logoutMutation,
    logout,
    logoutLoading: logoutMutation.isPending,
    onLogoutSuccess,
    /**
     * Init logged in user
     */
    updateUserInfo,
  }
}

export default useAuthentication
