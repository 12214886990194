import { IBaseResponse } from '@models/common.model'
import { MetricDto } from '@models/metric.model'
import { IProject } from '@models/projects.model'
import { SamplingPeriodDto, SamplingPeriodResponse } from '@models/samplingPeriods.model'
import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'

export interface ProjectListResponse {
  data: IProject[] | null
  count: number | null
  error: any
}

export interface ProjectDetailResponse {
  data: IProject | null
  error: any
}
export class ProjectService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  fetchAllProjects = async (): Promise<ProjectListResponse> => {
    try {
      const response = await this.client.from('projects').select(`*, organisation:organisations(id, name)`)
      if (response.error) {
        return { data: null, error: response?.error, count: null }
      }
      return { data: response.data, error: null, count: response.data.length }
    } catch (error: any) {
      return { data: null, error, count: null }
    }
  }
  fetchSamplingPeriodsByProjectId = async (projectId: string): Promise<IBaseResponse<SamplingPeriodResponse[]>> => {
    try {
      const response = await this.client.from('sampling_periods').select().eq('project_id', projectId)
      if (response.error) {
        return { data: null, error: response.error }
      }
      const samplingsPeriods = response.data.map(item => new SamplingPeriodDto(item))
      return { data: samplingsPeriods, error: response.error }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  fetchMetricsByProjectId = async (projectId: string): Promise<IBaseResponse<MetricDto[]>> => {
    try {
      const response = await this.client.from('metrics').select().eq('project_id', projectId)
      if (response.error) {
        return { data: null, error: response.error }
      }
      const metrics = response.data.map(item => new MetricDto(item))
      return { data: metrics, error: response.error }
    } catch (error: any) {
      return { data: null, error }
    }
  }
}

// Singleton pattern
const projectService = new ProjectService()

export default projectService
