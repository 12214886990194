import { ComponentsProps } from '@mui/material'

const MuiDivider: {
  defaultProps: ComponentsProps['MuiDivider']
} = {
  defaultProps: {
    color: '#E4E7EC',
  },
}

export default MuiDivider
