export interface LabelSetResponse {
  id: string
  name: string
  description: string | null
  is_master: boolean
  created_at?: string
  updated_at?: string | null
  deleted_at?: string | null
}

export class LabelSetDto {
  id: string
  name: string
  description: string | null
  isMaster: boolean
  createdAt?: string
  updatedAt?: string | null
  deletedAt?: string | null

  constructor(response: LabelSetResponse) {
    this.id = response.id
    this.name = response.name
    this.description = response.description
    this.isMaster = response.is_master
    this.createdAt = response.created_at
    this.updatedAt = response.updated_at
    this.deletedAt = response.deleted_at
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      isMaster: this.isMaster,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
    }
  }
}
