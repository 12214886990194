import { DialogActions, DialogActionsProps, Stack, StackProps, SxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const DialogHeaderBasic = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isFullScreen',
})<StackProps>(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: 'transparent',
  justifyContent: 'center',
  '.btn-close': {
    color: 'blueGrey.700',
  },
  '> .MuiTypography-root': {
    textAlign: 'center',
    color: 'blueGrey.600',
  },
}))

export const DialogHeaderFilled = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isFullScreen',
})<StackProps>(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.blue[800],
  color: 'white',
  justifyContent: 'space-between',
  '.btn-close': {
    color: 'white',
  },
}))

export const closeButton: SxProps = {
  position: 'absolute',
  padding: 2,
  right: 0,
  top: '4px',
}

export const DialogActionStyled = styled(DialogActions)<DialogActionsProps>(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.divider}`,
}))
