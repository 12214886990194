import { SxProps } from '@mui/material'

export const hiddenPoint: SxProps = {
  position: 'absolute',
  width: '1px',
  height: '1px',
  backgroundColor: 'transparent',
}

export const popoverPaper: SxProps = {
  borderRadius: 1,
  minWidth: 290,
}

export const hookContainer: SxProps = {
  position: 'absolute',
  top: 0,
  right: 0,
}

export const hook: SxProps = {
  position: 'absolute',
  left: -50,
  marginLeft: '',
  color: 'red',
  zIndex: 9999,
}

export const listSuggestion: SxProps = {
  height: 200,
  overflow: 'auto',
}

export const listItem: SxProps = {
  padding: '8px 16px',
  fontSize: '14px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: 'blue.100',
  },
}

export const header: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export const closeButton: SxProps = {
  padding: 0,
}

export const formLayoutContent: SxProps = {
  paddingBottom: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  borderBottom: '2px solid rgba(152, 173, 194, 0.3)',
}

export const creatLabelSetContent: SxProps = {
  display: 'flex',
  gap: '10px',
  flexDirection: 'column',
  minWidth: '300px',
}

export const textFieldForm: SxProps = {
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
  },
}