import { useSnackbar } from 'notistack'

export const useMozaicSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar()

  const enqueueErrorSnackbar = (message: string) => {
    enqueueSnackbar(message, { variant: 'error', autoHideDuration: 3000 })
  }

  const enqueueSuccessSnackbar = (message: string) => {
    enqueueSnackbar(message, { variant: 'success', autoHideDuration: 2000 })
  }

  const enqueueWarningSnackbar = (message: string) => {
    enqueueSnackbar(message, { variant: 'warning', autoHideDuration: 2000 })
  }

  return {
    enqueueSnackbar,
    enqueueErrorSnackbar,
    enqueueSuccessSnackbar,
    enqueueWarningSnackbar,
  }
}

export default useMozaicSnackbar
