import { Box } from '@components/atom'
import * as Styles from './PageLoading.styles'
import './loading.css'

export type PageLoadingProps = {
  /**
   * name is required, that help debug easier
   */
  name: string
  transparent?: boolean
}

export const PageLoading = ({ transparent = false, name }: PageLoadingProps) => {
  return (
    <Styles.PageLoadingStyled transparent={transparent} id={`${name}--loading`}>
      <Box component="span" className="loader" />
    </Styles.PageLoadingStyled>
  )
}

export default PageLoading
