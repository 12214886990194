import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import CircleIcon from '@mui/icons-material/Circle'

const HighConfidenceIcon = () => <PlayArrowIcon sx={{ width: 16, height: 16, color: 'success.main', transform: 'rotate(-90deg)' }} />

const MediumConfidenceIcon = () => <CircleIcon sx={{ width: 12, height: 12, color: 'warning.main' }} />

const LowConfidenceIcon = () => <PlayArrowIcon sx={{ width: 16, height: 16, color: 'error.main', transform: 'rotate(90deg)' }} />

export { HighConfidenceIcon, MediumConfidenceIcon, LowConfidenceIcon }
