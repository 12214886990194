import Icons from '@components/atom/Icons'
import { MenuSideBarItem } from '@components/template/Sidebar/Sidebar.types'
import { List, ListItemButton } from '@mui/material'
import { memo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { menuItemContainerStyle, menuWrapperStyle } from './style'

export interface IMenuListProps {
  menuItems: MenuSideBarItem[]
}

const MenuSideBar = memo(({ menuItems }: IMenuListProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const navigateItem = (link: string) => {
    navigate(link)
  }
  return (
    <List sx={menuWrapperStyle}>
      {menuItems.map((menuItem) => {
        const isSelected = menuItem.link === location.pathname
        return (
          <ListItemButton key={menuItem.id} onClick={() => navigateItem(menuItem.link)} sx={menuItemContainerStyle} selected={isSelected}>
            <Icons Icon={menuItem.icon} color={isSelected ? 'white' : 'currentColor'} />
            {menuItem.title}
          </ListItemButton>
        )
      })}
    </List>
  )
})

export default MenuSideBar
