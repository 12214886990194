import { Answer, Condition, FormResult, FormSurvey, LogicStatement, Operator } from '@models/formCatalog.model'
import _ from 'lodash'

// Demo data structure for storing answers
// const answers: { [key: string]: Answer } = {
//   A1: { id: 'pass', value: 10 },
//   A2: { id: 'fail', value: 5 },
// }

/**
 * * This function use to check the option of the question is selected (active) or not, based on the answers
 */
export function evaluateLogic(statement: LogicStatement, answers: { [key: string]: Answer }): boolean {
  const { operator, questionId, property, expectedValue } = statement
  const answer = answers[questionId]

  if (!answer || !(property in answer)) {
    return false // If the answer or property doesn't exist, return false
  }

  const actualValue = answer[property]

  switch (operator) {
    case 'eq':
      return actualValue === expectedValue
    case 'neq':
      return actualValue !== expectedValue
    case 'gt':
      return actualValue > expectedValue
    case 'gte':
      return actualValue >= expectedValue
    case 'lt':
      return actualValue < expectedValue
    case 'lte':
      return actualValue <= expectedValue
    default:
      throw new Error(`Unsupported operator: ${operator}`)
  }
}

export function checkActiveIf(activeIf: Condition[] | null, answers: { [key: string]: Answer } | null): boolean {
  if (!activeIf) return true // If there is no condition to check then the question is active
  if (!answers) return false // If there are no answers then the condition cannot be satisified so the question is not active
  return activeIf.every(([operator, questionId, property, expectedValue]) => {
    return evaluateLogic({ operator: operator as Operator, questionId, property, expectedValue }, answers)
  })
}

export function checkAllActiveQuestionsAnswered(form: FormSurvey): boolean {
  if (form.questions.length === 0) return true
  return form.questions
    .filter(question => checkActiveIf(question.activeIf, form.answers) || !question.activeIf)
    .every(question => form.answers[question.id] !== undefined)
}

export function evaluateFinalResult(form: FormSurvey): {
  bestResult: FormResult | null
  explanation: {
    totalScore: number
    groupScores: Record<string, number>
    groupsPassed: number
  }
} {
  if (form.questions.length === 0) {
    return { bestResult: form.results[0], explanation: { totalScore: 0, groupScores: {}, groupsPassed: 0 } }
  }

  const totalScore = Object.values(form.answers).reduce((sum, answer) => sum + (answer.score ?? 0), 0)

  const groupScores: { [key: string]: number } = {}
  form.questions.forEach(question => {
    if (question.groupId) {
      if (!groupScores[question.groupId]) {
        groupScores[question.groupId] = 0
      }
      groupScores[question.groupId] += form.answers[question.id]?.score || 0
    }
  })

  let groupsPassed = 0

  if (form.groups) {
    groupsPassed = form.groups.filter(group => groupScores[group.id] >= group.passScore).length
  }

  let bestResult: FormResult | null = null

  // Results should be in descending order so first one passed is the best
  for (const result of form.results) {
    const activeIfPassed = checkActiveIf(result.activeIf, form.answers)
    if (!activeIfPassed) {
      continue
    }
    const requiredQuestionsPassed = checkActiveIf(result.criteria.requiredQuestions, form.answers)
    if (!requiredQuestionsPassed) {
      continue
    }

    const totalScorePassed = totalScore >= result.criteria.minTotalScore
    const groupsScorePassed = result.criteria.minGroupsPassed === null || groupsPassed >= result.criteria.minGroupsPassed

    if (activeIfPassed && totalScorePassed && groupsScorePassed && requiredQuestionsPassed) {
      if (!bestResult || result.score > bestResult.score) {
        bestResult = result
      }
    }
  }

  const explanation = {
    totalScore,
    groupScores,
    groupsPassed,
  }

  return { bestResult, explanation }
}
