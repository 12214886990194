import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Anchor = styled(Box)<BoxProps & { x: number; y: number }>(({ x, y }) => ({
  position: 'absolute',
  top: y,
  left: x,
}))

export const StickyNoteStyled = styled(Box)<BoxProps & { scale: number; allowinteract?: boolean }>(({ theme, scale, allowinteract }) => ({
  position: 'absolute',
  border: '1px solid',
  borderColor: theme.palette.grey[200],
  borderRadius: '6px',
  borderBottomLeftRadius: 0,
  transform: `translate(0, -100%) scale(${scale})`,
  transformOrigin: 'bottom left',
  top: 0,
  left: 0,
  padding: '2px 6px',
  backgroundColor: 'white',
  fontSize: '12px',
  display: 'flex',
  gap: 4,
  justifyContent: 'start',
  alignItems: 'start',
  width: 'max-content',
  zIndex: 1,
  ':hover': allowinteract
    ? {
        zIndex: 2,
        boxShadow: theme.shadows[2],
        flexDirection: 'column',
        alignItems: 'start',
        '& > .actions': {
          display: 'flex',
        },
        '& > .note-container': {
          textWrap: allowinteract ? 'wrap' : 'nowrap',
        },
      }
    : {},
  '& > .actions': {
    display: 'none',
    justifyContent: 'space-between',
    width: '100%',
  },
  '& > .actions > .icon-button': {
    padding: '2px',
    cursor: 'pointer',
  },
  '& > .note-container': {
    maxWidth: '250px',
    textWrap: 'nowrap',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
  },
}))
