import type { SVGProps } from 'react'
const SvgIconCursor = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M17.088 8.98c.514-.2.77-.3.843-.442a.42.42 0 0 0-.005-.389c-.076-.139-.336-.232-.855-.418L3.83 2.977c-.425-.152-.637-.229-.776-.18a.42.42 0 0 0-.257.257c-.049.139.028.351.18.776L7.73 17.07c.187.52.28.779.42.855.12.066.266.068.388.005.141-.072.241-.33.441-.843l2.164-5.565c.04-.1.059-.151.09-.193a.4.4 0 0 1 .096-.098c.043-.03.093-.05.194-.089z"
    />
  </svg>
)
export default SvgIconCursor
