import useAppParams from '@hooks/useAppParams'
import { AnnotationDto } from '@models/annotation.model'
import { ICheckpointLocation } from '@models/checkpoint.model'
import { EObservationState, IObservation } from '@models/observation.model'
import { ObservationMedia } from '@models/observationMedia.model'
import { ISurvey } from '@models/survey.model'
import checkpointService from '@services/CheckpointService/CheckpointService'
import observationService from '@services/ObservationService/ObservationService'
import surveyService from '@services/SurveyService/SurveyService'
import { useMutation, useQuery } from '@tanstack/react-query'
import queryClient from '@utils/queryClient'
import {
  addAnnotationOfMedia,
  getAllMediaFromListObservation,
  removeAnnotationOfMedia,
  updateAnnotationOfMedia,
  updateMediaOfObservation,
} from '@utils/annotation.utils'
import _ from 'lodash'
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import useQueryCheckpointInSurvey from '@hooks/queries/useQueryCheckpointInSurvey/useQueryCheckpointInSurvey.hook'
import { FeatureCollection } from 'geojson'

interface CheckpointOverviewContextType {
  /**
   * Observation
   */
  observations: IObservation[]
  isLoadingObservations: boolean
  selectedObservation: IObservation | undefined
  setSelectedObservationId: React.Dispatch<React.SetStateAction<string>>
  refetchObservations: () => void
  unLinkToSurvey: (observationId: string) => Promise<any>
  linkToSurvey: (observationId: string) => Promise<any>
  updateStateLinkedObservation: ({ observationId, state }: { observationId: string; state: EObservationState }) => Promise<any>
  /**
   * Survey detail
   */
  surveyDetail: ISurvey | null
  refetchSurveyDetail: () => void
  isLoadingSurveyDetail: boolean
  /**
   * Observation Media
   *
   */
  selectedObservationMediaId: string | undefined
  selectedObservationMedia: ObservationMedia | null | undefined
  setSelectedObservationMediaId: (mediaId: string) => void
  allObservationMedia: ObservationMedia[]
  /**
   * Checkpoints
   */
  projectSurveyCheckpoints: FeatureCollection | null
  refetchCheckpoints: () => void

  checkpoints: ICheckpointLocation[]
  isLoadingCheckpoints: boolean
  refetchCheckpoint: () => void
  /**
   * Annotations
   */
  addAnnotationOfObservation: (annotation: AnnotationDto) => any
  updateAnnotationOfObservation: (annotation: AnnotationDto) => any
  removeAnnotationOfObservation: (annotationId: string) => any
}

interface CheckpointProviderProps {
  children: ReactNode
}

const CheckpointOverviewContext = createContext<CheckpointOverviewContextType | null>(null)

const CheckpointOverviewProvider: React.FC<CheckpointProviderProps> = ({ children }) => {
  const { mediaId, observationId, checkpointId, surveyId, projectId, updateUrlParam } = useAppParams()
  const [selectedObservationId, setSelectedObservationId] = useState(observationId ?? '')
  const [selectedObservationMediaId, setSelectedObservationMediaId] = useState<string>(mediaId ?? '')

  useEffect(() => {
    updateUrlParam('observationId', selectedObservationId)
  }, [selectedObservationId])

  useEffect(() => {
    updateUrlParam('mediaId', selectedObservationMediaId)
  }, [selectedObservationMediaId])

  const {
    data: { data: surveyDetail } = {},
    isLoading: isLoadingSurveyDetail,
    refetch: refetchSurveyDetail,
  } = useQuery({
    queryKey: ['surveyDetail', surveyId],
    queryFn: ({ queryKey: [_, surveyId] }) => {
      return surveyService.fetchSurveyDetail({ surveyId })
    },
  })

  const {
    data: { data: checkpoints = [] } = {},
    isLoading: isLoadingCheckpoints,
    refetch: refetchCheckpoint,
  } = useQuery({
    queryKey: ['fetchCheckpoints', surveyId],
    queryFn: ({ queryKey: [_, surveyId] }) => {
      return checkpointService.fetchCheckpointsBySurveyId({ surveyId })
    },
    enabled: Boolean(surveyId),
  })

  const { projectSurveyCheckpoints, refetchCheckpoints } = useQueryCheckpointInSurvey({ projectId, surveyId })

  const {
    data: { data: observations = [] } = {},
    isLoading: isLoadingObservations,
    isRefetching: isRefetchingObservations,
    refetch: refetchObservations,
  } = useQuery({
    queryKey: ['observations'],
    queryFn: ({ queryKey: [_] }) => {
      return observationService.fetchAllObservations({
        projectId: projectId ?? '',
        surveyId: surveyId ?? '',
        checkpointId: checkpointId ?? '',
        startDate: surveyDetail?.startDate,
        endDate: surveyDetail?.endDate,
      })
    },
    enabled: Boolean(surveyId && checkpointId && projectId),
  })

  const updateStateObservation = ({ observationId, state }: { observationId: string; state: EObservationState }) => {
    queryClient.setQueryData(['observations'], (oldData: any) => {
      if (!oldData) return oldData
      return {
        ...oldData,
        data: oldData.data.map((observation: any) => (observation.id === observationId ? _.set(observation, 'state', state) : observation)),
      }
    })
  }

  const updateObservation = (updatedObservation: IObservation) => {
    queryClient.setQueryData(['observations'], (oldData: any) => {
      if (!oldData) return oldData
      return {
        ...oldData,
        data: oldData.data.map((observation: any) => (observation.id === updatedObservation?.id ? updatedObservation : observation)),
      }
    })
  }

  const { mutateAsync: linkToSurvey } = useMutation({
    mutationKey: ['linkToSurvey'],
    mutationFn: (observationId: string) => observationService.linkToSurvey({ surveyId, observationId, checkpointId, projectId }),
    onSuccess: ({ data }) => {
      refetchCheckpoints()
      if (data) {
        updateObservation(data)
      }
    },
  })

  const { mutateAsync: unLinkToSurvey } = useMutation({
    mutationKey: ['unLinkToSurvey'],
    mutationFn: (observationId: string) => observationService.unLinkToSurvey({ surveyId, observationId }),
    onSuccess: ({ data }) => {
      refetchCheckpoints()
      if (data) {
        updateObservation(data)
      }
    },
  })

  const { mutateAsync: updateStateLinkedObservation } = useMutation({
    mutationKey: ['updateStateLinkedObservation'],
    mutationFn: ({ observationId, state }: { observationId: string; state: EObservationState }) =>
      observationService.updateStateOfLinkedObservation({ surveyId, observationId, state }),
    onSuccess: ({ data }) => {
      if (data) {
        updateStateObservation({ observationId: data.observationId, state: data?.state as EObservationState })
      }
    },
  })

  useEffect(() => {
    if (!_.isEmpty(observations)) {
      refetchObservations()
    }
  }, [checkpointId])

  // Remember the observation ids to avoid constant re-rendering. Array comparison does not work in useEffect
  const observationIds = useMemo(() => observations?.map(observation => observation.id).join(','), [observations])
  useEffect(() => {
    if (!_.isEmpty(observations)) {
      let initialObservation: IObservation | undefined = _.find(observations!, { id: observationId }) ?? _.first(observations)
      let initialMediaItem = _.find(initialObservation!.mediaList, { id: mediaId }) ?? _.first(initialObservation!.mediaList)
      setSelectedObservationId(initialObservation!.id)
      setSelectedObservationMediaId(initialMediaItem?.id)
    }
  }, [observationIds])

  const handleSetSelectedObservationId = (id: string) => {
    setSelectedObservationId(id)

    const selectedObs = _.find(observations, { id })
    const selectedMediaId = _.first(selectedObs?.mediaList)?.id
    setSelectedObservationMediaId(selectedMediaId)
  }

  const selectedObservation = useMemo(() => _.find(observations, { id: selectedObservationId }), [observations, selectedObservationId])

  const selectedObservationMedia = useMemo(() => {
    for (const observation of observations ?? []) {
      for (const media of observation.mediaList) {
        if (media.id === selectedObservationMediaId) {
          return media
        }
      }
    }
    return null
  }, [observations, selectedObservationMediaId])

  const addAnnotationOfObservation = (annotation: AnnotationDto) => {
    if (!observations) return
    const cloneObservations = _.cloneDeep(observations)
    const allMedia = getAllMediaFromListObservation(cloneObservations ?? [])
    const mediaNeedToUpdate = _.find(allMedia, { id: annotation.mediaId })
    if (!mediaNeedToUpdate) return
    const updatedMedia = addAnnotationOfMedia(mediaNeedToUpdate, annotation)
    let observationNeedToUpdateIndex = _.findIndex(observations, observation => {
      return _.findIndex(observation.mediaList, { id: updatedMedia.id }) >= 0
    })
    if (observationNeedToUpdateIndex < 0) return
    const updatedObservation = updateMediaOfObservation(cloneObservations[observationNeedToUpdateIndex], updatedMedia) as IObservation
    cloneObservations[observationNeedToUpdateIndex] = updatedObservation
    queryClient.setQueryData(['observations'], (oldData: any) => {
      if (!oldData) return oldData
      return {
        ...oldData,
        data: cloneObservations,
      }
    })
  }

  const removeAnnotationOfObservation = (annotationId: string) => {
    if (!observations) return
    const cloneObservations = _.cloneDeep(observations)
    const allMedia = getAllMediaFromListObservation(cloneObservations)
    const mediaNeedToUpdate = _.find(allMedia, media => {
      return _.findIndex(media.annotations, { id: annotationId }) >= 0
    })
    if (!mediaNeedToUpdate) return
    const updatedMedia = removeAnnotationOfMedia(mediaNeedToUpdate, annotationId)
    let observationNeedToUpdateIndex = _.findIndex(cloneObservations, observation => {
      return _.findIndex(observation.mediaList, { id: updatedMedia.id }) >= 0
    })
    if (observationNeedToUpdateIndex < 0) return
    const updatedObservation = updateMediaOfObservation(cloneObservations[observationNeedToUpdateIndex], updatedMedia) as IObservation
    cloneObservations[observationNeedToUpdateIndex] = updatedObservation

    queryClient.setQueryData(['observations'], (oldData: any) => {
      if (!oldData) return oldData
      return {
        ...oldData,
        data: cloneObservations,
      }
    })
  }

  const updateAnnotationOfObservation = (annotation: AnnotationDto) => {
    if (!observations) return
    const cloneObservations = _.cloneDeep(observations)
    const allMedia = getAllMediaFromListObservation(cloneObservations)
    const mediaNeedToUpdate = _.find(allMedia, { id: annotation.mediaId })
    if (!mediaNeedToUpdate) return
    const updatedMedia = updateAnnotationOfMedia(mediaNeedToUpdate, annotation)
    let observationNeedToUpdateIndex = _.findIndex(cloneObservations, observation => {
      return _.findIndex(observation.mediaList, { id: updatedMedia.id }) >= 0
    })
    if (observationNeedToUpdateIndex < 0) return
    const updatedObservation = updateMediaOfObservation(cloneObservations[observationNeedToUpdateIndex], updatedMedia) as IObservation
    cloneObservations[observationNeedToUpdateIndex] = updatedObservation
    queryClient.setQueryData(['observations'], (oldData: any) => {
      if (!oldData) return oldData
      return {
        ...oldData,
        data: cloneObservations,
      }
    })
  }

  const allObservationMedia = useMemo(() => {
    return _.flatMap(observations, (observation: IObservation) => observation.mediaList) ?? []
  }, [observations])

  return (
    <CheckpointOverviewContext.Provider
      value={{
        /**
         * Observation
         */
        observations: observations ?? [],
        selectedObservation,
        setSelectedObservationId: handleSetSelectedObservationId as any,
        isLoadingObservations: isRefetchingObservations || isLoadingObservations,
        refetchObservations,
        unLinkToSurvey,
        linkToSurvey,
        updateStateLinkedObservation,
        /**
         * Survey detail
         */
        isLoadingSurveyDetail,
        refetchSurveyDetail,
        surveyDetail: surveyDetail ?? null,
        /**
         * Observation Media
         */
        selectedObservationMedia,
        setSelectedObservationMediaId,
        selectedObservationMediaId,
        allObservationMedia,
        /**
         * Checkpoints
         */
        projectSurveyCheckpoints,
        refetchCheckpoints,

        checkpoints: checkpoints ?? [],
        isLoadingCheckpoints,
        refetchCheckpoint,
        /**
         * Annotations
         */
        addAnnotationOfObservation,
        removeAnnotationOfObservation,
        updateAnnotationOfObservation,
      }}
    >
      {children}
    </CheckpointOverviewContext.Provider>
  )
}

const useCheckpointOverviewContext = (): CheckpointOverviewContextType => {
  const context = useContext(CheckpointOverviewContext)

  if (!context) {
    throw new Error('useCheckpointOverviewContext must be used within a CheckpointOverviewProvider')
  }

  return context
}

export { CheckpointOverviewProvider, useCheckpointOverviewContext }
