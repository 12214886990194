// eslint-disable-line import/no-webpack-loader-syntax
import { CSSProperties, forwardRef } from 'react'
import { StyledMaps } from './Maps.styles'
import { BoxProps } from '../Box'

export type MapsProps = {
  isFullScreen: boolean
  width?: CSSProperties['width']
  height?: CSSProperties['height']
} & BoxProps

/**
 * This component powered by Mapbox
 * @param {ref} is containerRef
 */
export const Maps = forwardRef(({ isFullScreen, width, height, ...rest }: MapsProps, ref: any) => {
  return <StyledMaps {...rest} isFullScreen={isFullScreen} ref={ref} width={width} height={height} />
})

export default Maps
