import { Box, IconButton } from '@mui/material'
import { Anchor, StickyLabelStyled } from './StickyLabelStyled'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import { HighConfidenceIcon, MediumConfidenceIcon, LowConfidenceIcon } from '../ConfidenceIcons/ConfidenceIcons'
import { IconLabelTag, IconSpecies } from '@icons/index'
import Icons from '../Icons'
import { CSSProperties, Fragment } from 'react'
import Render from '../Render/Render'

export type StickyLabelProps = {
  id: string
  scale: number
  x: number
  y: number
  type?: 'species' | 'label'
  label: string
  note?: string
  confidenceRate?: number
  onClick?: any
  onClickEdit?: any
  onDelete?: any
  allowinteract?: boolean
  cursor?: CSSProperties['cursor']
  collapseLabel?: boolean
}

export const StickyLabel = ({
  id,
  scale,
  x,
  y,
  label,
  note,
  confidenceRate,
  onClick,
  onClickEdit,
  onDelete,
  type,
  allowinteract = true,
  collapseLabel = false,
}: StickyLabelProps) => {
  const adjustedScale = scale == 1 ? scale : 1 / scale

  return (
    <Anchor x={x} y={y}>
      <StickyLabelStyled scale={adjustedScale} onClick={onClick} {...(allowinteract ? { allowinteract } : {})}>
        <div className="label-container">
          <Box mt="3px" mb="3px">
            {type === 'species' && <Icons width={12} height={12} Icon={IconSpecies} color="green.700" />}
            {type === 'label' && <Icons width={12} height={12} Icon={IconLabelTag} color="grey.700" />}
          </Box>
          <Render test={!collapseLabel}>
            <Fragment>
              {label}
              {typeof confidenceRate === 'number' &&
                (confidenceRate <= 50 ? <LowConfidenceIcon /> : confidenceRate <= 75 ? <MediumConfidenceIcon /> : <HighConfidenceIcon />)}
              <Box
                sx={{
                  backgroundColor:
                    confidenceRate === undefined ? '#fff0' : confidenceRate <= 50 ? 'error' : confidenceRate <= 75 ? 'warning' : 'success',
                }}
              />
            </Fragment>
          </Render>
        </div>
        <Render test={!collapseLabel}>
          <Fragment>
            {note && <div className="note">{note}</div>}
            <div className="actions">
              <IconButton
                className="icon-button"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!allowinteract) return
                  onClickEdit(id)
                }}
              >
                <EditOutlinedIcon sx={{ width: 16, height: 16 }} />
              </IconButton>
              <IconButton
                className="icon-button"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!allowinteract) return
                  onDelete(id)
                }}
              >
                <DeleteIcon sx={{ width: 16, height: 16, color: 'red' }} />
              </IconButton>
            </div>
          </Fragment>
        </Render>
      </StickyLabelStyled>
    </Anchor>
  )
}

export default StickyLabel
