import { ICheckpointLocationWithState } from '@models/checkpoint.model'
import checkpointService from '@services/CheckpointService/CheckpointService'
import { useQuery } from '@tanstack/react-query'
import { convertToCheckpointGeoJson } from '@utils/map.utils'
import { useMemo } from 'react'

const useQueryCheckpointInSurvey = ({ projectId, surveyId }: { projectId?: string; surveyId?: string }) => {
  // TODO: Filter query by date
  const allCheckpoints = useQuery({
    queryKey: ['fetch-checkpoint-not-associated', projectId],
    queryFn: () => checkpointService.fetchCheckpointsAcrossProject({ projectId: projectId ?? '' }),
    enabled: !!projectId,
    refetchOnMount: false,
  })

  // TODO: Filter query by date
  const surveyCheckpoints = useQuery({
    queryKey: ['fetch-checkpoint-associated', surveyId],
    queryFn: () => checkpointService.fetchCheckpointsBySurveyId({ surveyId: surveyId ?? '' }),
    enabled: !!surveyId,
    refetchOnMount: false,
  })

  const projectSurveyCheckpoints = useMemo(() => {
    const allCheckpointsData = allCheckpoints.data?.data
    const surveyCheckpointsData = surveyCheckpoints.data?.data

    if (!allCheckpointsData) return null
    if (!surveyCheckpointsData) return convertToCheckpointGeoJson({ checkpoints: allCheckpointsData })

    const combinedCheckpoints = allCheckpointsData.map(cp => {
      const found = surveyCheckpointsData.find(surveyCp => surveyCp.checkpointId === cp.checkpointId)
      return { ...cp, isLinked: !!found } as ICheckpointLocationWithState
    })

    return convertToCheckpointGeoJson({ checkpoints: combinedCheckpoints})
  }, [allCheckpoints.data, surveyCheckpoints.data])

  const refetchCheckpoints = () => {
    allCheckpoints.refetch()
    surveyCheckpoints.refetch()
  }

  return {
    projectSurveyCheckpoints,
    loadingAssociated: surveyCheckpoints.isFetching,
    loadingCheckpointAcross: allCheckpoints.isFetching,
    refetchCheckpoints,
  }
}

export default useQueryCheckpointInSurvey
