import { styled } from '@mui/material/styles'
import { MuiOtpInput, MuiOtpInputProps } from 'mui-one-time-password-input'

export type IOtpFormFieldProps = {} & Omit<MuiOtpInputProps, 'render'>

const CustomOtpInput = styled(MuiOtpInput)(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  maxWidth: '550px',
  marginInline: 'auto',

  '& .MuiOutlinedInput-root': {
    input: {
      width: '80px',
      height: '80px',
      border: `2px solid ${theme.palette.secondary.main}`,
      borderRadius: '16px',
      fontSize: '48px',
      boxSizing: 'border-box',
      textAlign: 'center',
      color: theme.palette.secondary.main,
    },

    '&.Mui-focused': {
      borderColor: theme.palette.secondary.main,
      boxShadow: `0 0 0 2px ${theme.palette.secondary.main}`,
      padding: '2px',
      borderRadius: '16px',
    },
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}))

export default function OtpFormField(props: IOtpFormFieldProps) {
  return <CustomOtpInput {...props} />
}
