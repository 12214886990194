import { Divider, List, ListItem, Skeleton } from '@mui/material'
import _isEmpty from 'lodash/isEmpty'
import _nth from 'lodash/nth'
import React, { forwardRef, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Box, ButtonProps, ConfidenceRateSelector, HStack, Loader, TextField, Typography } from '@components/atom'
import useCreateLabel from '@hooks/mutation/useCreateLabel/useCreateLabel'
import useQuerySearchLabels from '@hooks/queries/useQuerySearchLabels/useQuerySearchLabels'
import useAppParams from '@hooks/useAppParams'
import { LabelDto } from '@models/label.model'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'

import Popover from '@components/atom/Popover/Popover'
import PopoverActions from '@components/atom/Popover/PopoverActions'
import PopoverContent from '@components/atom/Popover/PopoverContent'
import PopoverHeader from '@components/atom/Popover/PopoverHeader'
import FormCreateLabel, { CreateLabelData } from '@components/molecule/FormCreateLabel/FormCreateLabel'
import useQueryLabelSet from '@hooks/queries/useQueryLabelSets/useQueryLabelSets'
import * as styles from './LabelingPopover.styles'
import ENV from '@configs/env'
import { EMode } from '../LabelingImage'
import { CompactNoteInput } from '@components/molecule'

export type LabelingImagePopoverProps = {
  anchorElRef: any
  mode: EMode
  open: boolean
  onClose: any
  onSubmit: (label: LabelDto, confidenceRate: number, note: string, isCustomLabel?: boolean) => void
}

const LabelingPopover = forwardRef(({ anchorElRef, mode, open, onClose, onSubmit }: LabelingImagePopoverProps, ref) => {
  const [value, setValue] = useState<string>('')
  const [isCreatingSpeciesLabel, setIsCreatingSpeciesLabel] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [confidenceRate, setConfidenceRate] = useState<number>(100)
  const [note, setNote] = useState('')
  const [showNoteInput, setShowNoteInput] = useState(false)

  const { projectId } = useAppParams()
  const { labels, search, loading } = useQuerySearchLabels({
    projectId,
    labelSetId: mode === EMode.SpeciesLabel ? ENV.LABEL_SET_PLANT_SPECIES_ID : null,
  })
  const { createLabelAsync, isLoading: isCreatingLabel } = useCreateLabel()
  const { labelSets } = useQueryLabelSet()

  const handleBackClick = () => {
    setIsCreatingSpeciesLabel(false)
  }

  const handleNoteChange = (note: string) => {
    setNote(note)
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'ArrowUp') {
      setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : labels.length - 1))
    } else if (event.key === 'ArrowDown') {
      setSelectedIndex(prevIndex => (prevIndex < labels.length - 1 ? prevIndex + 1 : 0))
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const handleSubmitSearch = () => {
    const selectedSuggestion = _nth(labels, selectedIndex)
    if (selectedSuggestion) {
      onSubmit(selectedSuggestion, confidenceRate, note)
      return
    }
  }

  const handleSubmitBySuggestion = (suggestion: LabelDto) => {
    onSubmit(suggestion, confidenceRate, note)
  }

  const handleCreateCustomLabel = async (data: CreateLabelData) => {
    const label = {
      code: data.code || '',
      description: data.title || '',
      id: uuidv4(),
      name: data.title || '',
      project_id: projectId || '',
      label_set_id: data.labelSet?.id ?? null,
    }
    if (label) {
      const { data: newCustomLabel } = await createLabelAsync(label)
      if (newCustomLabel) {
        onSubmit(newCustomLabel, confidenceRate, note, true)
      }
    }
  }

  const buttons: ButtonProps[] = [
    {
      children: 'Create a label',
      startIcon: <AddCircleOutlineOutlinedIcon />,
      variant: 'outlined',
      buttonSize: 'sm',
      fullWidth: true,
      onClick: () => setIsCreatingSpeciesLabel(true),
    },
  ]

  return (
    <>
      <Box ref={ref} sx={styles.hiddenPoint} />
      <Popover
        slotProps={{
          paper: { sx: styles.popoverPaper },
        }}
        anchorEl={anchorElRef.current}
        open={open}
        onClose={onClose}
      >
        {!isCreatingSpeciesLabel && (
          <Box position="relative">
            <PopoverHeader onClose={onClose}>{mode === EMode.SpeciesLabel ? 'Add a species label' : 'Add a label'}</PopoverHeader>
            <PopoverContent sx={{ padding: 0 }}>
              <Box paddingX={2} sx={styles.formLayoutContent}>
                <TextField
                  sx={styles.textFieldForm}
                  size="small"
                  autoFocus
                  label="Label"
                  placeholder="Search by short code..."
                  type="text"
                  fullWidth
                  value={value}
                  inputProps={{ autoComplete: 'off' }}
                  onChange={e => {
                    setValue(e.target.value)
                    setSelectedIndex(0)
                    search(e.target.value ?? '')
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter' && !_isEmpty(labels)) {
                      handleSubmitSearch()
                    } else if (e.key === 'Escape') {
                      onClose()
                    }
                  }}
                />
                <ConfidenceRateSelector
                  title="Confidence score:"
                  onChange={newValue => {
                    if (newValue) {
                      setConfidenceRate(newValue)

                      if (newValue !== 100) {
                        setShowNoteInput(true)
                      }
                    }
                  }}
                />
                <CompactNoteInput
                  showNoteInput={showNoteInput}
                  setShowNoteInput={setShowNoteInput}
                  note={note}
                  handleNoteChange={handleNoteChange}
                />
              </Box>

              {isCreatingLabel && (
                <ListItem sx={styles.listItemLoading}>
                  <Loader size="small" />
                </ListItem>
              )}
              <List sx={styles.listSuggestion}>
                {loading
                  ? Array.from({ length: 6 }).map((_, index) => (
                      <ListItem key={index} divider sx={{ padding: 1, height: 33 }}>
                        <Skeleton height={33} width="100%" />
                      </ListItem>
                    ))
                  : labels.map((suggestion, index) => (
                      <React.Fragment key={suggestion.id}>
                        <ListItem
                          onClick={() => handleSubmitBySuggestion(suggestion)}
                          sx={{ ...styles.listItem, backgroundColor: index === selectedIndex ? 'blue.100' : 'white' }}
                        >
                          <HStack justifyContent="space-between" width="100%">
                            {suggestion.name}
                            <Typography>{suggestion.code}</Typography>
                          </HStack>
                        </ListItem>
                        {index < labels.length - 1 && <Divider />}
                      </React.Fragment>
                    ))}
              </List>
            </PopoverContent>
            <PopoverActions sx={{ borderTop: 0 }} buttons={buttons} />
          </Box>
        )}

        {isCreatingSpeciesLabel && (
          <FormCreateLabel
            labelSets={labelSets}
            mode={mode}
            onClose={onClose}
            onCreate={handleCreateCustomLabel}
            handleBackClick={handleBackClick}
          />
        )}
      </Popover>
    </>
  )
})

export default LabelingPopover
