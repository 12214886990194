import { FormCategory, FormResult, FormSurvey } from '@models/formCatalog.model'
import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { checkAllActiveQuestionsAnswered, evaluateFinalResult } from './FormSurveyEvaluationContext.helper'

interface FormSurveyEvaluationProviderProps {
  children: ReactNode
  defaultSchema?: FormSurvey | null
}

interface FormSurveyEvaluationContextType {
  selectedForm: FormSurvey | null
  setSelectedForm: React.Dispatch<React.SetStateAction<FormSurvey | null>>
  formCategoryData: FormCategory | undefined
  setFormCategoryData: React.Dispatch<React.SetStateAction<FormCategory | undefined>>
  formResultData: FormResult | null
  setFormResultData: React.Dispatch<React.SetStateAction<FormResult | null>>
}

const FormSurveyEvaluationContext = createContext<FormSurveyEvaluationContextType | null>(null)

const FormSurveyEvaluationProvider: React.FC<FormSurveyEvaluationProviderProps> = ({ defaultSchema = null, children }) => {
  const [selectedForm, setSelectedForm] = useState<FormSurvey | null>(defaultSchema)
  const [formCategoryData, setFormCategoryData] = useState<FormCategory | undefined>()
  const [formResultData, setFormResultData] = useState<FormResult | null>(null)

  return (
    <FormSurveyEvaluationContext.Provider
      value={{
        selectedForm: selectedForm,
        setSelectedForm: setSelectedForm,
        formCategoryData: formCategoryData,
        setFormCategoryData: setFormCategoryData,
        formResultData: formResultData,
        setFormResultData: setFormResultData,
      }}
    >
      {children}
    </FormSurveyEvaluationContext.Provider>
  )
}

const useFormSurveyEvaluationContext = (): FormSurveyEvaluationContextType & {
  getFinalResult: (updatedForm: FormSurvey) => {
    bestResult: FormResult | null
    explanation: {
      totalScore: number
      groupScores: Record<string, number>
      groupsPassed: number
    }
  } | null
  isAllActiveQuestionsAnswered: () => boolean
} => {
  const context = useContext(FormSurveyEvaluationContext)

  if (!context) {
    throw new Error('useFormSurveyEvaluationContext must be used within a FormSurveyEvaluationProvider')
  }

  const isAllActiveQuestionsAnswered = useCallback(() => {
    if (!context.selectedForm || (!context.selectedForm.answers && context.selectedForm?.questions.length > 0)) return false
    return checkAllActiveQuestionsAnswered(context.selectedForm)
  }, [context.selectedForm])

  const getFinalResult = (updatedForm: FormSurvey) => {
    if (!updatedForm || (!updatedForm.answers && updatedForm.questions.length > 0)) return null
    const isAllActiveQuestionsAnswered = checkAllActiveQuestionsAnswered(updatedForm)
    return isAllActiveQuestionsAnswered ? evaluateFinalResult(updatedForm) : null
  }

  return { ...context, getFinalResult, isAllActiveQuestionsAnswered }
}

export { FormSurveyEvaluationProvider, useFormSurveyEvaluationContext }
