import ENV from '@configs/env'
import { SupabaseClient } from '@supabase/supabase-js'
import logger from '@utils/logger'
import axios, { Axios } from 'axios'
import camelcaseKeys from 'camelcase-keys'

// Create SupabaseClient singleton instance
const supabaseClient = new SupabaseClient(ENV.SUPABASE_URL, ENV.SUPABASE_KEY, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    storage: window.localStorage,
    storageKey: 'supabase-auth-key',
  },
})

export class MozaicSupebaseClient {
  protected client: SupabaseClient
  protected axios: Axios
  private signedUrlExpire = 3600

  constructor() {
    this.client = supabaseClient
    this.axios = axios
  }

  protected toCamelCase = (data: any): any => {
    return camelcaseKeys(data, { deep: true })
  }

  generateSignedUrl = async (bucketId: string, filePath: string) => {
    const { data, error } = await this.client.storage.from(bucketId).createSignedUrl(filePath, this.signedUrlExpire)
    if (error || !data) {
      logger.error('Error generating signed URL:' + error.message)
    }

    return data?.signedUrl
  }

  formatDateSubmit = (date?: string | Date): string => {
    if (typeof date === 'string') return date
    if (date instanceof Date) return date.toISOString()
    throw new Error('Invalid date type')
  }
}

export default supabaseClient
