import PrivateRoute from '@components/template/PrivateRoute'
import { lazy } from 'react'
import ERoutePaths from './routes.path'

const ProjectsPage = lazy(() => import('@pages/ProjectsPage/ProjectsPage'))

const projectsRoute = {
  projects: {
    path: ERoutePaths.Projects,
    element: (
      <PrivateRoute title="Mozaic Earth Portal | Projects">
        <ProjectsPage />
      </PrivateRoute>
    ),
  },
}

export default projectsRoute
