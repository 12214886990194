import { Typography } from '@components/atom'
import { styled, SxProps } from '@mui/material'

export const CardDescription = styled(Typography)`
  width: 100%;
  max-height: 80px;
  word-break: break-word;
  text-align: left;
  overflow-y: auto;
`
export const cardBase = {
  ':hover': { backgroundColor: 'grey.100' },
  cursor: 'pointer',
}

export const card: SxProps = {
  borderColor: 'primary.main',
  ...cardBase,
}

export const cardActive: SxProps = {
  border: '1px solid',
  borderColor: 'primary.main',
  ...cardBase,
}
