export class Logger {
  constructor() {
    if (process.env.NODE_ENV === 'production') {
      console.log = () => {}
    }
  }

  info(message: string): void {
    const time = new Date().toISOString()
    if (process.env.NODE_ENV === 'development') {
      console.log(`Info: [${time}] ${message}`)
    }
  }

  error(message: string, error?: any): void {
    const time = new Date().toISOString()
    if (process.env.NODE_ENV === 'development') {
      console.error(`Error: [${time}] ${message}`, error)
    }
  }
}
const logger = new Logger()

export default logger
