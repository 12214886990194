import { forwardRef } from 'react'
import { LinearProgress as MuiLinearProgress, LinearProgressProps as MuiLinearProgressProps, linearProgressClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import MozaicEarthGreen from '@styles/theme/colors/green'

const BorderLinearProgress = styled(MuiLinearProgress)(() => ({
  height: 8,
  borderRadius: 5,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: MozaicEarthGreen[500],
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: MozaicEarthGreen.A100,
  },
}))

const LinearProgress = forwardRef<HTMLDivElement, MuiLinearProgressProps>((props, ref) => {
  return <BorderLinearProgress ref={ref} {...props} />
})

export default LinearProgress
