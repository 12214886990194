import { Box, ButtonProps } from '@components/atom'
import PopoverActions from '@components/atom/Popover/PopoverActions'
import PopoverContent from '@components/atom/Popover/PopoverContent'
import PopoverHeader from '@components/atom/Popover/PopoverHeader'
import { LabelSetDto } from '@models/labelSet.model'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import { IconButton } from '@mui/material'
import { ReactNode } from 'react'
import { useForm } from 'react-hook-form'
import { isSpeciesLabelSet } from '@utils/annotation'
import TextFormField from '../TextFormField'
import * as styles from './FormCreateLabel.styles'
import { EMode } from '@components/organism/LabelingImage/LabelingImage'

export type CreateLabelData = {
  title?: string
  code?: string
  labelSet?: LabelSetDto | null
}

type CreateLabelPopoverProps = {
  labelSets: LabelSetDto[]
  mode: EMode
  onClose: () => void
  onCreate: (values: CreateLabelData) => void
  addonComponent?: ReactNode
  handleBackClick: () => void
}

const defaultLabelSet = (labelSets: LabelSetDto[]) => labelSets.find(isSpeciesLabelSet) || null

const FormCreateLabel = ({ labelSets, mode, onClose, onCreate, handleBackClick }: CreateLabelPopoverProps) => {
  const { handleSubmit, control, reset } = useForm<CreateLabelData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      title: '',
      code: '',
      labelSet: mode === EMode.SpeciesLabel ? defaultLabelSet(labelSets) : null,
    },
  })

  const buttons: ButtonProps[] = [
    {
      children: 'Create (enter)',
      variant: 'contained',
      buttonSize: 'sm',
      color: 'secondary',
      type: 'submit',
    },
  ]

  const handleFormSubmit = async (data: CreateLabelData) => {
    await onCreate(data)
    reset()
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <PopoverHeader
        onClose={onClose}
        addonComponent={
          <IconButton onClick={handleBackClick} sx={styles.closeButton}>
            <ChevronLeftRoundedIcon />
          </IconButton>
        }
      >
        Create a label
      </PopoverHeader>
      <PopoverContent>
        <Box sx={styles.creatLabelSetContent}>
          <TextFormField
            label="Title*"
            name="title"
            control={control}
            textFieldProps={{
              size: 'small',
              placeholder: 'Label name',
            }}
            rules={{ required: 'Title is required' }}
          />
          <TextFormField
            label="Code"
            name="code"
            control={control}
            textFieldProps={{
              size: 'small',
              placeholder: 'Short code',
            }}
          />
        </Box>
      </PopoverContent>
      <PopoverActions buttons={buttons} />
    </form>
  )
}

export default FormCreateLabel
