import ENV from '@configs/env'
import useAuthStore from '@stores/Auth/authStore'
import { UserbackProvider } from '@userback/react'
import logger from '@utils/logger'
import React from 'react'

const CustomUserbackProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuthStore()

  logger.info(String(user?.email))
  logger.info(String(String(ENV.USERBACK_KEY).length))

  // const user_data = {
  //   id: user?.id,
  //   info: {
  //     name: user?.email,
  //     email: user?.email,
  //   },
  // }

  // const ubOptions = { user_data: user_data, priority: 'high', autohide: false }

  return (
    <UserbackProvider token={ENV.USERBACK_KEY} options={{ email: user?.email, name: user?.id }}>
      {children}
    </UserbackProvider>
  )
}

export default CustomUserbackProvider
