import { useAppLoadingContext } from '@context/AppLoading'
import surveyService from '@services/SurveyService/SurveyService'
import { useMutation } from '@tanstack/react-query'
import _ from 'lodash'
import { useEffect } from 'react'

function useQuerySurveysByProjectId({ projectId }: { projectId?: string }) {
  const { setLoading } = useAppLoadingContext()

  const mutation = useMutation({
    mutationKey: ['fetch-boundaries-by-survey-id', projectId],
    mutationFn: metricId => surveyService.fetchSurveys({ projectId: projectId as string, metricId: metricId as any }),
  })

  useEffect(() => {
    if (projectId && _.isEmpty(mutation.data)) {
      mutation.mutate()
    }
  }, [])

  useEffect(() => {
    setLoading(prev => ({
      ...prev,
      tasks: mutation.isPending,
    }))
  }, [mutation.isPending])

  return { surveys: mutation.data?.data ?? [], ...mutation }
}

export default useQuerySurveysByProjectId
