import React from 'react'

import { useAuthentication } from '@hooks/useAuthentication/useAuthentication.hook'
import { Sidebar } from '@components/organism'
import { Stack } from '@components/atom'

export type MainLayoutProps = {
  children: React.ReactNode
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { logout, logoutLoading } = useAuthentication()

  return (
    <Stack direction="row">
      <Sidebar logoutLoading={logoutLoading} onClickLogout={logout} />
      <Stack direction="column" width="100%" height={'100vh'}>
        {children}
      </Stack>
    </Stack>
  )
}

export default MainLayout
