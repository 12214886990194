import React, { forwardRef, useRef, useState } from 'react'
import { Box, MenuItem, Select as MuiSelect, SelectProps as MuiSelectProps, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import _, { isArray } from 'lodash'
import { Typography } from '..'

export const SearchStyled = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary,
      },
    },
    '&.Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary,
      },
    },
  },
}))

export type SelectProps = {
  id?: string
  allowSearch?: boolean
  children?: React.ReactNode
  valueFieldName?: string
  labelFieldName?: string
  options?: any[]
  renderCustomValue?: (option: any) => string | React.ReactNode
  customOptionRender?: (value: any) => string | React.ReactNode
} & MuiSelectProps

export const Select = forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      id,
      allowSearch = false,
      children,
      placeholder,
      labelFieldName = 'label',
      valueFieldName = 'value',
      options,
      renderCustomValue,
      customOptionRender,
      ...rest
    },
    ref,
  ) => {
    const searchRef = useRef<any>()
    const [searchValue, setSearchValue] = useState('')

    const handleSearch = (e: any) => {
      const { value } = e.target
      setSearchValue(value)
    }

    return (
      <MuiSelect
        id={id}
        labelId="id"
        multiple={false}
        displayEmpty
        onOpen={() => searchRef.current?.focus()}
        renderValue={(selected: any) => {
          const singleSelectPlaceholder = !selected && placeholder
          const multipleSelectPlaceholder = isArray(selected) && !selected?.length && !!placeholder

          if (singleSelectPlaceholder || multipleSelectPlaceholder) {
            return <Typography color="grey.400">{placeholder}</Typography>
          }

          if (renderCustomValue) {
            return renderCustomValue(selected)
          }

          return _.find(options, { [valueFieldName]: selected })?.[labelFieldName]
        }}
        {...rest}
        ref={ref}
      >
        {placeholder && (
          <MenuItem sx={{ display: 'none' }}>
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {children}
        {allowSearch && (
          <Box p={1} width="100%">
            <SearchStyled
              sx={{
                width: 'calc(100% - 16px)',
              }}
              autoFocus
              onKeyDown={e => {
                e.stopPropagation()
                return e
              }}
              ref={searchRef}
              type="text"
              onChange={handleSearch}
            />
          </Box>
        )}
        {options
          ?.filter(option => {
            if (typeof option === 'string') {
              return option?.toLowerCase().includes(searchValue.toLowerCase())
            }
            return customOptionRender
              ? customOptionRender(option)
              : option[labelFieldName]?.toLowerCase().includes(searchValue.toLowerCase())
          })
          .map((option, index) => {
            return (
              <MenuItem key={`${option}-${index}`} value={option[valueFieldName]}>
                {customOptionRender ? customOptionRender(option) : option[labelFieldName]}
              </MenuItem>
            )
          })}
      </MuiSelect>
    )
  },
)

Select.displayName = 'Select'

export default Select
