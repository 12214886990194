import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@emotion/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Inspector } from 'react-dev-inspector'

import { AppVersion } from '@components/atom'
import { AppLoadingProvider } from '@context/AppLoading'
import queryClient from '@utils/queryClient'

import CustomUserbackProvider from '@context/CustomUserbackProvider'
import router from './configs/routes/routes.config'
import CustomSnackbarProvider from './context/CustomSnackBar'
import lightTheme from './styles/theme/light'
import ENV from '@configs/env'

function App({ ...props }) {
  return (
    <HelmetProvider>
      {ENV.ENV === 'development' && <Inspector />}
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <CustomSnackbarProvider>
            <AppLoadingProvider>
              <CustomUserbackProvider>
                <RouterProvider router={router} {...props} />
                <AppVersion />
              </CustomUserbackProvider>
            </AppLoadingProvider>
          </CustomSnackbarProvider>
          {ENV.ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default App
