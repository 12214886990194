import { SxProps } from '@mui/material'
import { lightThemeDefault } from '@styles/theme/light'

export const layout: SxProps = {
  padding: 4,
  backgroundColor: 'white',
  backgroundImage: `radial-gradient(${lightThemeDefault.palette?.blue[100]} 0.5px, white 0.5px)`,
  backgroundSize: '10px 10px',
  width: '100vw',
  boxSizing: 'border-box',
  overflow: 'hidden',
}

export const toolBar: SxProps = {
  minWidth: 400,
  width: '25%',
}

export const labeling: SxProps = {
  maxHeight: 'calc(100vh - 64px)', // 64px padding
  // 416 =  400 + padding 16px
  minWidth: 'calc(100% - 416px)',
}
