import ENV from '@configs/env'
import maplibregl from 'maplibre-gl'

export class MozaicMap {
  instance: maplibregl.Map

  protected defaultZoom = 15
  protected minZoom = 10

  constructor(options: maplibregl.MapOptions) {
    const { zoom, minZoom, ...rest } = options
    this.instance = new maplibregl.Map({
      attributionControl: {
        compact: false,
        customAttribution: undefined,
      },
      style: `${ENV.MAP_STYLE}?key=${ENV.MAP_KEY}`,
      zoom: zoom ?? this.defaultZoom,
      minZoom: minZoom ?? this.minZoom,
      maxTileCacheZoomLevels: 20,
      maplibreLogo: false,
      ...rest,
    })
  }
}
