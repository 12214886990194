import { useQuerySurveysByProjectId } from '@hooks/queries'
import useAppParams from '@hooks/useAppParams'
import { convertSurveyToGeoJson } from '@utils/map.utils'
import { FeatureCollection } from 'geojson'
import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react'

// Define the shape of the context
interface ListSurveyContextType {
  surveys: any[]
  surveyLoading: boolean
  refetchSurveys: any
  selectedSurveyPolygon: string
  setSelectedSurveyPolygon: React.Dispatch<React.SetStateAction<string>>
  boundaries: FeatureCollection | null | undefined
}

// Define the props for the provider component
interface ListSurveyProviderProps {
  children: ReactNode
}

// Create the context
const ListSurveyContext = createContext<ListSurveyContextType | null>(null)

// Create the provider component
const ListSurveyProvider: React.FC<ListSurveyProviderProps> = ({ children }) => {
  const { projectId } = useAppParams()
  const { surveys, isPending: surveyLoading, mutate } = useQuerySurveysByProjectId({ projectId: projectId })
  const [selectedSurveyPolygon, setSelectedSurveyPolygon] = useState('')

  const boundaries = useMemo(() => {
    if (surveys) return convertSurveyToGeoJson({ surveys, selectedSurveyPolygon })
  }, [surveys, selectedSurveyPolygon])

  return (
    <ListSurveyContext.Provider
      value={{ surveys, surveyLoading, refetchSurveys: mutate, selectedSurveyPolygon, setSelectedSurveyPolygon, boundaries }}
    >
      {children}
    </ListSurveyContext.Provider>
  )
}

// Create a custom hook to use the context
const useListSurveyContext = () => {
  const context = useContext(ListSurveyContext)

  if (!context) {
    throw new Error('useListSurveyContext must be used within a ListSurveyProvider')
  }

  return context
}

export { ListSurveyProvider, useListSurveyContext }
