import { INote, IPoint } from '@models/point.model'
import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'

// Define types for the context values
interface LabelingContextType {
  selectedImage: any
  setSelectedImage: React.Dispatch<React.SetStateAction<any>>
  transformRef: React.RefObject<ReactZoomPanPinchRef>
  setPoints: React.Dispatch<React.SetStateAction<IPoint[]>>
  points: IPoint[]
  setNotes: React.Dispatch<React.SetStateAction<INote[]>>
  notes: INote[]
  scale: number
  setScale: React.Dispatch<React.SetStateAction<number>>
}

// Define types for the provider's props
interface LabelingProviderProps {
  children: ReactNode
}

// Create the context with a default value of null
const LabelingContext = createContext<LabelingContextType | null>(null)

// Create a provider component
const LabelingProvider: React.FC<LabelingProviderProps> = ({ children }) => {
  const transformRef = React.useRef<ReactZoomPanPinchRef | null>(null)
  const [selectedImage, setSelectedImage] = useState<Blob | null>()
  const [points, setPoints] = useState<IPoint[]>([])
  const [notes, setNotes] = useState<INote[]>([])
  const [scale, setScale] = useState<number>(0.5)

  return (
    <LabelingContext.Provider
      value={{
        transformRef,
        setPoints,
        points,
        scale,
        setScale,
        setNotes,
        notes,
        selectedImage,
        setSelectedImage,
      }}
    >
      {children}
    </LabelingContext.Provider>
  )
}

// Create a custom hook to use the LabelingContext
const useLabelingContext = (): LabelingContextType & {
  updateNote: (note: INote) => void
  addNote: (note: INote) => void
  removeNote: (noteId: string) => void
  resetTool: () => void
} => {
  const context = useContext(LabelingContext)

  if (!context) {
    throw new Error('useLabeling must be used within a LabelingProvider')
  }

  const { notes, setNotes, points, setPoints } = context

  const updateNote = (note: INote) => {
    // As notes can be added directly or via points update both lists
    const updatedNotes = notes.map(existingNote => (existingNote.id === note.id ? { ...existingNote, ...note } : existingNote))
    setNotes(updatedNotes)

    const updatePoints = points.map(existingPoint => (existingPoint.id === note.id ? { ...existingPoint, ...note } : existingPoint))
    setPoints(updatePoints)
  }

  const addNote = (note: INote) => context?.setNotes([...notes, note])

  const removeNote = (noteId: string) => context?.setNotes(notes.filter(n => n.id !== noteId))

  const resetTool = useCallback(() => {
    context.setNotes([])
    context.setPoints([])
  }, [])

  return { updateNote, addNote, removeNote, resetTool, ...context }
}

export { LabelingProvider, useLabelingContext }
