import { SxProps, Theme } from '@mui/material'

export const menuWrapperStyle: SxProps = {
  padding: '0 4px',
  display: 'flex',
  justifyItems: 'flex-start',
  gap: '4px',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '100%',
}

export const menuItemContainerStyle = (theme: Theme) => ({
  padding: '8px 12px',
  fontWeight: '600',
  borderRadius: '10px',
  width: '100%',
  color: theme.palette.common.black,
  display: 'flex',
  gap: '12px',
  '&:hover': {
    backgroundColor: theme.palette.blue[200],
    color: theme.palette.common.white,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.blue[800],
      color: theme.palette.common.white,
    },
  },
})
