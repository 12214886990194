import ENV from '@configs/env'
import { AnnotationDto } from '@models/annotation.model'
import { LabelDto } from '@models/label.model'
import { LabelSetDto } from '@models/labelSet.model'
import { ObservationMedia } from '@models/observationMedia.model'
import { INote, IPoint } from '@models/point.model'
import _ from 'lodash'

export const mapAnnotationToPoint = (annotations: AnnotationDto[]) => {
  const points: IPoint[] = annotations
    .map(annotation => ({
      id: annotation.id,
      confidenceRate: annotation.confidenceRating ?? 0,
      label: annotation.label,
      note: annotation.note,
      x: _.first(annotation.shapePoints) ?? 0,
      y: _.nth(annotation.shapePoints, 1) ?? 0,
    }))
    .filter(point => point.label)
  return points
}

export const mapAnnotationToNotes = (annotations: AnnotationDto[]) => {
  const points: INote[] = annotations
    .filter(annotation => !annotation.labelId)
    .map(annotation => ({
      id: annotation.id,
      note: annotation.note,
      x: _.first(annotation.shapePoints) ?? 0,
      y: _.nth(annotation.shapePoints, 1) ?? 0,
    }))
    .filter(point => point.note)
  return points
}

export const groupAnnotationByLabels = (annotations: AnnotationDto[]) => {
  const grouped = _.groupBy(annotations, annotation => annotation?.labelId ?? '')

  return _.mapValues(grouped, annotations => ({
    label: annotations[0]?.label,
    annotations: annotations as AnnotationDto[],
  }))
}

export const isSpeciesLabel = (label?: LabelDto) => {
  return label?.labelSetId === ENV.LABEL_SET_PLANT_SPECIES_ID
}

export const isSpeciesLabelSet = (labelSet?: LabelSetDto) => {
  return labelSet?.id === ENV.LABEL_SET_PLANT_SPECIES_ID
}

export const classifyAnnotations = (annotations: AnnotationDto[] = []) => {
  const labelAnnotations = annotations.filter(a => a?.labelId) || []
  const speciesAnnotations = _.filter(labelAnnotations, an => isSpeciesLabel(an.label)) as AnnotationDto[]
  const customLabelAnnotations = _.filter(
    labelAnnotations,
    an => an.label?.labelSetId !== ENV.LABEL_SET_PLANT_SPECIES_ID,
  ) as AnnotationDto[]
  const noteAnnotations = _.filter(annotations, an => an.note) as AnnotationDto[]

  return [speciesAnnotations, customLabelAnnotations, noteAnnotations]
}

export const countAnnotationAndMedia = (label: LabelDto, observationMedias: ObservationMedia[]) => {
  const annotationCount = observationMedias.reduce((count, media) => {
    const mediaAnnotations = media.annotations || []
    const matchingAnnotations = mediaAnnotations.filter(annotation => annotation.labelId === label.id)
    return count + matchingAnnotations.length
  }, 0)

  const mediaCount = observationMedias.filter(media => {
    const mediaAnnotations = media.annotations || []
    return mediaAnnotations.some(annotation => annotation.labelId === label.id)
  }).length

  return { annotationCount, mediaCount }
}
