import ERoutePaths from '@configs/routes/routes.path'
import SvgIconLayersThree from '../../../icons/IconLayersThree'
import SvgIconLifeBuoy from '../../../icons/IconLifeBuoy'
import SvgIconSettings from '../../../icons/IconSettings'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { MenuSideBarItem } from './Sidebar.types'
import { v4 as uuidv4 } from 'uuid'

export interface IListSideBar {
  topMenu: MenuSideBarItem[]
  bottomMenu: MenuSideBarItem[]
}
export const listSideBar: IListSideBar = {
  topMenu: [
    {
      title: 'Projects',
      link: ERoutePaths.Projects,
      icon: SvgIconLayersThree,
      id: uuidv4(),
    },
    {
      title: 'Reports',
      link: ERoutePaths.Reports,
      icon: AssessmentIcon,
      id: uuidv4(),
    },
  ],
  bottomMenu: [
    {
      title: 'Support',
      link: ERoutePaths.NotFound,
      icon: SvgIconLifeBuoy,
      id: uuidv4(),
    },
    { title: 'Settings', link: ERoutePaths.NotFound, icon: SvgIconSettings, id: uuidv4() },
  ],
}
