import { forwardRef } from 'react'
import MuiPagination from '@mui/material/Pagination'
import { PaginationProps } from '@mui/material/Pagination'

//TODO: Custom style pagination
const Pagination = forwardRef<HTMLDivElement, PaginationProps>((props, ref) => {
  return <MuiPagination ref={ref} {...props} />
})

export default Pagination
