import { ComponentsOverrides, Theme } from '@mui/material'

const MuiFormControl: {
  styleOverrides: ComponentsOverrides<Theme>['MuiFormControl']
} = {
  styleOverrides: {
    root: () => ({
      '.MuiFormHelperText-root': {
        // position: 'absolute',
        // bottom: '-20px',
        marginLeft: 0,
      },
    }),
  },
}

export default MuiFormControl
