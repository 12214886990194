import { AnnotationDto } from '@models/annotation.model'
import { IObservation } from '@models/observation.model'
import { ObservationMedia } from '@models/observationMedia.model'
import _ from 'lodash'

export const getAllMediaFromListObservation = (listObservations: IObservation[] | IObservation[]) => {
  return listObservations.reduce((allMedias, observation) => {
    if (observation.mediaList) {
      allMedias.push(...observation.mediaList)
    }
    return allMedias
  }, [] as ObservationMedia[])
}

export const updateMediaOfObservation = (observation: IObservation, media: ObservationMedia) => {
  const clonedObservation = _.cloneDeep(observation)
  const mediaList = _.cloneDeep(observation.mediaList)
  const index = _.findIndex(mediaList, { id: media.id })
  if (index >= 0 && mediaList) {
    mediaList[index] = media
    Object.assign(clonedObservation, { mediaList })
    return clonedObservation
  }
  return observation
}

export const addAnnotationOfMedia = (media: ObservationMedia, annotation: AnnotationDto) => {
  const clonedMedia = _.cloneDeep(media)
  const annotations = _.cloneDeep(media.annotations)
  if (annotations) {
    annotations.push(annotation)
    Object.assign(clonedMedia, { annotations })
    return clonedMedia
  }
  return media
}

export const updateAnnotationOfMedia = (media: ObservationMedia, annotation: AnnotationDto) => {
  const clonedMedia = _.cloneDeep(media)
  const annotations = _.cloneDeep(media.annotations)
  const index = _.findIndex(annotations, { id: annotation.id })
  if (index >= 0 && annotations) {
    // TECH DEBT: When label notes are update the label information is not piped through
    // Retain the same information for now to allow it to continue to be displayed
    if (!annotation.label && annotations[index].label) {
      annotation.label = annotations[index].label
    }
    annotations[index] = annotation
    Object.assign(clonedMedia, { annotations })
    return clonedMedia
  }
  return media
}

export const removeAnnotationOfMedia = (media: ObservationMedia, annotationId: string) => {
  const clonedMedia = _.cloneDeep(media)
  const annotations = _.cloneDeep(media.annotations)
  const index = _.findIndex(annotations, { id: annotationId })
  if (index >= 0 && annotations) {
    annotations.splice(index, 1)
    Object.assign(clonedMedia, { annotations })
    return clonedMedia
  }
  return media
}
