import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'
import { IBaseResponse } from '@models/common.model'
import { TileLayerDto } from '@models/tileLayer.model'

export class TileLayerService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  fetchTileLayersByProjectId = async (projectId: string): Promise<IBaseResponse<TileLayerDto[] | null>> => {
    try {
      const response = await this.client.from('tile_layers').select('*').eq('project_id', projectId)
      if (response.error) {
        return { data: null, error: response?.error }
      }

      const tileLayers: TileLayerDto[] = response.data.map((tileLayer: any) => new TileLayerDto(tileLayer))
      return { data: tileLayers, error: null }
    } catch (error: any) {
      return { data: null, error }
    }
  }
}

const tileLayerService = new TileLayerService()

export default tileLayerService
