import { IBaseResponse } from '@models/common.model'
import { EObservationState, IObservation, ISurveysObservation } from '@models/observation.model'
import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'
import _ from 'lodash'
import { FetchObservationRequest } from './ObservationService.model'

export class ObservationService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  getImageSource = async (bucketId: string, filePath: string): Promise<any> => {
    const signedUrl = await this.generateSignedUrl(bucketId, filePath)
    if (signedUrl) {
      const response = await this.axios.get(signedUrl, { responseType: 'blob' })
      const objectUrl = URL.createObjectURL(response.data)
      return objectUrl
    }
    throw new Error('Can not get image')
  }

  protected formatObservation = (observation?: any, isLinkedToSurvey?: boolean): IObservation => {
    if (!observation) return observation
    const camelCase = this.toCamelCase(observation)
    if (!_.isEmpty(camelCase?.surveysObservations)) {
      const first = _.first(camelCase?.surveysObservations) as any
      return {
        ...camelCase,
        isLinkedToSurvey,
        state: first?.state,
        surveysObservations: isLinkedToSurvey ? camelCase?.surveysObservations : [],
      }
    }

    return { ...camelCase, isLinkedToSurvey, surveysObservations: isLinkedToSurvey ? camelCase?.surveysObservations : [] }
  }

  fetchLinkedObservations = async ({
    surveyId,
    checkpointId,
    projectId,
    useMediaList,
  }: FetchObservationRequest): Promise<IBaseResponse<IObservation[]>> => {
    try {
      let selectSQL = `
        id,
        checkpoint_locations(checkpoint_key),
        media_guides(display_name),
        observation_key,
        observation_date,
        checkpoint_id,
        surveys_observations(state, survey_id)
      `

      // Conditionally add media list if useMediaList is true
      if (useMediaList) {
        selectSQL += `,
          media_list:observation_media(*, annotations:annotations(*, label:labels(*)))
        `
      }

      const query = this.client
        .from('observations')
        .select(selectSQL)
        .eq('surveys_observations.survey_id', surveyId)
        .eq('project_id', projectId)
        .not('surveys_observations', 'is', null)
        .order('observation_key', { ascending: true })
        .order('observation_date', { ascending: true })

      if (checkpointId) {
        query.eq('checkpoint_id', checkpointId).order('display_order', { referencedTable: 'observation_media', ascending: true })
      }

      const response = await query

      if (response.error) {
        return { data: null, error: response?.error }
      }

      const normalizedData = _.map(response.data, o => this.formatObservation(o, true))

      return {
        data: normalizedData,
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  fetchUnlinkedObservations = async ({
    projectId,
    checkpointId,
    startDate,
    endDate,
    surveyId,
    useMediaList,
  }: FetchObservationRequest): Promise<IBaseResponse<IObservation[]>> => {
    try {
      let selectSQL = `
      id,
      checkpoint_locations(checkpoint_key),
      media_guides(display_name),
      observation_key,
      observation_date,
      checkpoint_id,
      surveys_observations(state, survey_id)
    `
      if (useMediaList) {
        selectSQL += ',media_list:observation_media(*, annotations:annotations(*, label:labels(*)))'
      }

      const query = this.client
        .from('observations')
        .select(selectSQL)
        .neq('surveys_observations.survey_id', surveyId)
        .eq('project_id', projectId)
        .order('observation_key', { ascending: true })
        .order('observation_date', { ascending: true })

      if (checkpointId) {
        query.eq('checkpoint_id', checkpointId).order('display_order', { referencedTable: 'observation_media', ascending: true })
      }

      if (startDate) {
        query.gte('observation_date', startDate)
      }
      if (endDate) {
        query.lte('observation_date', endDate)
      }

      const response = await query

      if (response.error) {
        return { data: null, error: response?.error }
      }

      return {
        data: _.map(response.data, o => this.formatObservation(o, false)),
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  fetchAllObservations = async ({
    surveyId,
    checkpointId,
    startDate,
    endDate,
    projectId,
  }: {
    projectId: string
    surveyId: string
    checkpointId: string
    startDate: any
    endDate: any
  }): Promise<IBaseResponse<IObservation[]>> => {
    try {
      const promises = Promise.all([
        this.fetchLinkedObservations({ projectId, surveyId, checkpointId, useMediaList: true }),
        this.fetchUnlinkedObservations({ projectId, surveyId, checkpointId, startDate, endDate, useMediaList: true }),
      ])
      const [linkedObservationsRes, observationsRes] = await promises
      if (!linkedObservationsRes.error && !observationsRes.error) {
        const unlinkedObservationsRes = _.differenceBy(observationsRes.data ?? [], linkedObservationsRes.data ?? [], 'id')
        const mergedObservations = (linkedObservationsRes.data ?? []).concat(unlinkedObservationsRes)
        return { data: mergedObservations, error: null }
      }
      return { data: [], error: new Error('Something wrong!! Can not get observations') }
    } catch (error) {
      return { data: [], error: error }
    }
  }

  fetchOneLinkedObservation = async ({
    observationId,
    surveyId,
    checkpointId,
    projectId,
  }: {
    observationId: string
    surveyId: string
    checkpointId: string
    projectId: string
  }): Promise<IBaseResponse<IObservation>> => {
    try {
      const response = await this.client
        .from('observations')
        .select(
          `
      id,
      checkpoint_locations(checkpoint_key),
      media_guides(display_name),
      observation_key,
      observation_date,
      surveys_observations(state),
      media_list:observation_media(*, annotations:annotations(*, label:labels(*)))
    `,
        )
        .eq('surveys_observations.survey_id', surveyId)
        .eq('checkpoint_id', checkpointId)
        .eq('project_id', projectId)
        .eq('id', observationId)
        .order('display_order', { referencedTable: 'observation_media', ascending: true })
        .single()

      if (response.error) {
        return { data: null, error: response?.error }
      }

      return {
        data: this.formatObservation(response.data, true),
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  fetchOneObservation = async ({ observationId }: { observationId: string }): Promise<IBaseResponse<IObservation>> => {
    try {
      const query = this.client
        .from('observations')
        .select(
          `
      id,
      checkpoint_locations(checkpoint_key),
      media_guides(display_name),
      observation_key,
      observation_date,
      surveys_observations(state, survey_id),
      media_list:observation_media(*, annotations:annotations(*, label:labels(*)))
    `,
        )
        .eq('id', observationId)
        .order('display_order', { referencedTable: 'observation_media', ascending: true })
        .single()

      const response = await query

      if (response.error) {
        return { data: null, error: response?.error }
      }

      return {
        data: this.formatObservation(response.data, false),
        error: null,
      }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  linkToSurvey = async ({
    observationId,
    surveyId,
    checkpointId,
    projectId,
  }: {
    observationId: string
    surveyId: string
    checkpointId: string
    projectId: string
  }): Promise<IBaseResponse<IObservation>> => {
    try {
      const response = await this.client
        .from('surveys_observations')
        .insert([{ observation_id: observationId, survey_id: surveyId, state: EObservationState.Open }])

      if (response.error) {
        return { data: null, error: response?.error }
      }
      const newObservationRes = await this.fetchOneLinkedObservation({ observationId, checkpointId, projectId, surveyId })

      return { data: newObservationRes?.data, error: null }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  unLinkToSurvey = async ({
    observationId,
    surveyId,
  }: {
    observationId: string
    surveyId: string
  }): Promise<IBaseResponse<IObservation>> => {
    try {
      const response = await this.client.from('surveys_observations').delete().eq('observation_id', observationId).eq('survey_id', surveyId)

      if (response.error) {
        return { data: null, error: response?.error }
      }

      const newObservationRes = await this.fetchOneObservation({ observationId })

      return { data: newObservationRes.data, error: null }
    } catch (error: any) {
      return { data: null, error }
    }
  }

  updateStateOfLinkedObservation = async ({
    observationId,
    surveyId,
    state,
  }: {
    observationId: string
    surveyId: string
    state: EObservationState
  }): Promise<IBaseResponse<ISurveysObservation>> => {
    try {
      const response = await this.client
        .from('surveys_observations')
        .update({ state })
        .eq('observation_id', observationId)
        .eq('survey_id', surveyId)
        .select('*')
        .single()

      if (response.error) {
        return { data: null, error: response?.error }
      }

      return { data: this.toCamelCase(response.data), error: null }
    } catch (error: any) {
      return { data: null, error }
    }
  }
}

// Singleton pattern
const observationService = new ObservationService()

export default observationService
