import { SxProps } from '@mui/material'

export const badgeList: SxProps = {
  position: 'absolute',
  top: '8px',
  right: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  gap: 1,
}

export const badge: SxProps = {
  padding: '4px',
  borderRadius: '50%',
}

export const speciesBadge: SxProps = {
  ...badge,
  backgroundColor: 'green.100',
  color: 'green.700',
}

export const noteBadge: SxProps = {
  ...badge,
  backgroundColor: 'purple.100',
  color: 'purple.700',
}

export const customLabel: SxProps = {
  ...badge,
  backgroundColor: 'grey.100',
  color: 'grey.700',
}
