import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import useActivityTracker from '@hooks/useActivityTracker'
import { useAuthentication } from '@hooks/useAuthentication/useAuthentication.hook'
import supabaseClient from '@utils/supabase/MozaicSupebaseClient'
import { PageLoading } from '@components/molecule'

export const PrivateRoute = ({ title, children }: { title: string; children: any }) => {
  const { onLogoutSuccess, logout, updateUserInfo } = useAuthentication()
  const [isLoading, setIsLoading] = useState(true)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useActivityTracker({
    onTimeout: () => logout({ type: 'auto' }),
    timeoutInMinutes: 60,
  })

  useEffect(() => {
    const {
      data: { subscription },
    } = supabaseClient.auth.onAuthStateChange((_event, session) => {
      if (!session) {
        onLogoutSuccess()
      } else if (session) {
        updateUserInfo({ email: session?.user?.email, id: session?.user?.id })
      }
      if (session && isLoading) {
        setIsLoading(false)
      }
    })

    return () => {
      subscription.unsubscribe()
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  return (
    <>
      {isLoading && <PageLoading name="private-route" />}
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Suspense fallback={null}>{children}</Suspense>
    </>
  )
}

export default React.memo(PrivateRoute)
