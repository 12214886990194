import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Anchor = styled(Box)<BoxProps & { x: number; y: number }>(({ x, y }) => ({
  position: 'absolute',
  top: y,
  left: x,
}))

export const StickyLabelStyled = styled(Box)<BoxProps & { scale: number; allowinteract?: boolean }>(({ theme, scale, allowinteract }) => ({
  position: 'absolute',
  border: '1px solid',
  borderColor: theme.palette.grey[200],
  borderRadius: '6px',
  borderBottomLeftRadius: 0,
  transform: `translate(0, -100%) scale(${scale})`,
  transformOrigin: 'bottom left',
  top: 0,
  left: 0,
  padding: '2px 6px',
  backgroundColor: 'white',
  fontSize: '12px',
  display: 'flex',
  gap: 4,
  justifyContent: 'start',
  alignItems: 'center',
  width: 'max-content',
  zIndex: 1,
  ':hover': allowinteract
    ? {
        zIndex: 2,
        flexDirection: 'column',
        alignItems: 'start',
        '& > .note': {
          display: 'block',
        },
        '& > .actions': {
          display: 'flex',
        },
      }
    : {},
  '& > .note': {
    maxWidth: '250px',
    display: 'none',
    fontSize: '10px',
    textWrap: allowinteract ? 'wrap' : 'nowrap',
  },
  '& > .actions': {
    display: 'none',
    justifyContent: 'space-between',
    width: '100%',
  },
  '& > .actions > .icon-button': {
    padding: '2px',
    cursor: 'pointer',
  },
  '& > .label-container': {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 2px',
    gap: 4,
  },
  '& .dot': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '4px',
  },
}))
