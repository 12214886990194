import Table from '@mui/material/Table'
import _get from 'lodash/get'
import React, { memo } from 'react'

import EachComponent from '@components/atom/ForEachComponent/ForEachComponent'
import Render from '@components/atom/Render/Render'
import { TableBody, TableCell, TableCellProps, TableContainer, TableContainerProps, TableHead, TableRow } from '@components/atom/Table'

export type TColumn<T> = {
  key: string
  displayName: string | React.ReactNode | number
  renderCell?: ({ row, data }: { row: T | any; data: T[] | any }) => React.ReactNode
  renderHeader?: ({ displayName }: { displayName: any }) => React.ReactNode
} & TableCellProps

export type SimpleTableProps<T> = {
  data: T[]
  columns: TColumn<T>[]
  onItemClick: (item: T | any) => void
  sx?: TableContainerProps['sx']
}

export const SimpleTable = memo(<T,>({ data, columns, onItemClick, sx }: SimpleTableProps<T>) => {
  return (
    <TableContainer sx={sx}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {Object.values(columns).map((column, i) => (
              <React.Fragment key={i}>
                {column.renderHeader ? (
                  column.renderHeader({
                    displayName: column.displayName,
                  })
                ) : (
                  <TableCell key={i} sx={{ paddingY: '8px', fontWeight: '600', position: 'sticky', top: 0 }}>
                    {column.displayName}
                  </TableCell>
                )}
              </React.Fragment>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <Render test={data && data.length > 0}>
            <EachComponent
              of={data}
              render={(row, i) => (
                <TableRow key={i} onClick={() => onItemClick(row)}>
                  {Object.values(columns).map(column => (
                    <React.Fragment key={column.key}>
                      {column.renderCell ? (
                        column.renderCell({
                          row,
                          data,
                        })
                      ) : (
                        <TableCell {...column} key={column.key}>
                          {_get(row, column.key, '') as React.ReactNode}
                        </TableCell>
                      )}
                    </React.Fragment>
                  ))}
                </TableRow>
              )}
            />
          </Render>
        </TableBody>
      </Table>
    </TableContainer>
  )
})
export default SimpleTable
