import tinycolor from 'tinycolor2'

const baseColor = '#D7EBEF'

const MozaicEarthTeal = {
  50: tinycolor(baseColor).lighten(40).toHexString(),
  100: '#D7EBEF',
  200: '#B3D9E0',
  300: tinycolor(baseColor).lighten(10).toHexString(),
  400: tinycolor(baseColor).lighten(5).toHexString(),
  500: '#49A1B1',
  600: '#3A808D',
  700: tinycolor(baseColor).darken(5).toHexString(),
  800: '#1C3E44',
  900: tinycolor(baseColor).darken(10).toHexString(),
  A100: tinycolor(baseColor).lighten(50).toHexString(),
  A200: tinycolor(baseColor).lighten(40).toHexString(),
  A400: tinycolor(baseColor).lighten(30).toHexString(),
  A700: tinycolor(baseColor).lighten(20).toHexString(),
}

export default MozaicEarthTeal
