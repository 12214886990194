import { Box, ButtonGroup, Typography } from '@components/atom'
import { Button, useTheme } from '@mui/material'
import { useState } from 'react'
import { HighConfidenceIcon, MediumConfidenceIcon, LowConfidenceIcon } from '../ConfidenceIcons/ConfidenceIcons'

export type ConfidenceRateSelectorProps = {
  title: string
  onChange?: (value: number) => void
}

const defaultLabels: { label: string; value: number }[] = [
  { label: 'Low', value: 50 },
  { label: 'Med', value: 75 },
  { label: 'High', value: 100 },
]

export const ConfidenceRateSelector = ({ title, onChange }: ConfidenceRateSelectorProps) => {
  const { palette } = useTheme()

  const [selectedValue, setSelectedValue] = useState(defaultLabels[defaultLabels.length - 1].value) // Defaults to most confident

  const handleButtonClick = (value: number) => {
    setSelectedValue(value)
    if (onChange) {
      onChange(value) // Notify parent of the selected value
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        '& > legend': { mt: 0.5 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography component="legend" mb={1}>
        {title}
      </Typography>

      <ButtonGroup sx={{ display: 'flex', ml: 2, boxShadow: 'none' }}>
        {' '}
        {/* Removed gap between buttons */}
        {defaultLabels.map(({ label, value }) => (
          <Button
            key={value}
            variant="contained"
            onClick={() => handleButtonClick(value)}
            sx={{
              boxShadow: 'none',
              backgroundColor: selectedValue === value ? palette.blue[100] : 'grey.200',
              color: selectedValue === value ? palette.blue[700] : 'black',
              '&:hover': {
                backgroundColor: selectedValue === value ? palette.blue[100] : 'grey.200',
                color: selectedValue === value ? palette.blue[700] : palette.blue[400],
              },
              minWidth: 'auto',
              fontSize: '0.75rem',
              px: 1.25,
              borderRadius: 1,
            }}
            endIcon={value === 50 ? <LowConfidenceIcon /> : value === 75 ? <MediumConfidenceIcon /> : <HighConfidenceIcon />}
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  )
}

export default ConfidenceRateSelector
