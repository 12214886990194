import type { SVGProps } from 'react'
const SvgIconLabelTag = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M6.667 6.667h.008M1.667 4.334v3.728c0 .408 0 .612.046.804q.061.256.2.481c.103.169.247.313.535.601l6.39 6.39c.99.99 1.486 1.486 2.056 1.671a2.5 2.5 0 0 0 1.546 0c.57-.185 1.065-.68 2.055-1.67l1.844-1.844c.99-.99 1.485-1.485 1.67-2.055a2.5 2.5 0 0 0 0-1.546c-.185-.57-.68-1.065-1.67-2.055L9.948 2.448c-.288-.288-.432-.432-.6-.535a1.7 1.7 0 0 0-.482-.2c-.192-.046-.396-.046-.804-.046H4.334c-.934 0-1.4 0-1.757.182-.314.16-.569.414-.728.728-.182.357-.182.823-.182 1.757m5.417 2.333a.417.417 0 1 1-.834 0 .417.417 0 0 1 .834 0"
    />
  </svg>
)
export default SvgIconLabelTag
