import MuiTableBody, { TableBodyProps as MuiTableBodyProps } from '@mui/material/TableBody'
import React, { memo } from 'react'

export type TableCellProps = MuiTableBodyProps & {
  children?: React.ReactNode
}

export const TableBody = memo(({ children, ...rest }: TableCellProps) => {
  return <MuiTableBody {...rest}>{children}</MuiTableBody>
})

export default TableBody
