import { SxProps } from '@mui/material'

export const container = ({ width, height, maxHeight }: any): SxProps => ({
  position: 'relative',
  display: 'inline-block',
  borderRadius: 1,
  '.react-transform-wrapper': {
    width,
    height,
    maxHeight,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
  },
  boxShadow: '1',
  width: width,
  height: height,
})

export const clickArea = ({ width, height, cursor }: any) => ({
  width,
  height,
  cursor,
  position: 'relative',
})

export const dummyElement = {
  position: 'absolute',
  width: '1px',
  height: '1px',
  backgroundColor: 'transparent',
}
