import { BoxProps, Button, Loader, VStack } from '@components/atom'
import Icons from '@components/atom/Icons'
import { IconLabelTag, IconNote, IconSpecies } from '@icons/index'
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded'
import { Box } from '@mui/material'
import observationService from '@services/ObservationService/ObservationService'
import { useQuery } from '@tanstack/react-query'
import { memo, useEffect } from 'react'
import * as styles from './ObservationImage.styles'

export type ObservationImageProps = {
  id: string
  src: string
  bucketId: string
  isShowSpeciesLabel?: boolean
  isShowNoteLabel?: boolean
  isShowCustomLabel?: boolean
  selectedObservationMediaId?: string
  onLoaded?: (image: Blob) => void
  onClick?: (e: any, image: any) => void
} & Omit<BoxProps, 'onClick'>

export const ObservationImage = memo(
  ({
    src,
    bucketId,
    id,
    isShowSpeciesLabel,
    isShowNoteLabel,
    isShowCustomLabel,
    selectedObservationMediaId,
    sx = {},
    onLoaded,
    onClick,
    ...rest
  }: ObservationImageProps) => {
    const {
      data: image,
      isLoading,
      isError,
      refetch,
    } = useQuery({
      queryKey: [id],
      queryFn: () => observationService.getImageSource(bucketId, src || ''),
      enabled: !!src,
      refetchOnWindowFocus: false, // Disable refetching when window is focused
      refetchOnMount: false, // Disable refetching when component mounts
      refetchOnReconnect: false, // Disable refetching when reconnecting after losing connection
      staleTime: Infinity, // Prevent automatic refetching as long as the data is considered fresh
    })

    useEffect(() => {
      if (image && selectedObservationMediaId === id) {
        onLoaded?.(image)
      }
    }, [image, selectedObservationMediaId])

    const handleClick = (e: any) => onClick?.(e, image)

    if (isError) {
      return (
        <Box sx={sx} position="relative" bgcolor="grey.200">
          <Button
            onClick={() => refetch()}
            buttonSize="sm"
            sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            startIcon={<ReplayRoundedIcon fontSize="small" />}
          >
            Retry
          </Button>
        </Box>
      )
    }

    if (isLoading) {
      return (
        <Box sx={sx} position="relative" bgcolor="grey.200">
          <Loader size="small" color="dark" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
        </Box>
      )
    }

    return (
      <Box position="relative">
        <VStack sx={styles.badgeList}>
          {isShowSpeciesLabel && (
            <Box sx={styles.speciesBadge}>
              <Icons width={14} height={14} Icon={IconSpecies} color="inherit" />
            </Box>
          )}
          {isShowNoteLabel && (
            <Box sx={styles.noteBadge}>
              <Icons width={14} height={14} Icon={IconNote} color="inherit" />
            </Box>
          )}
          {isShowCustomLabel && (
            <Box sx={styles.customLabel}>
              <Icons width={14} height={14} Icon={IconLabelTag} color="inherit" />
            </Box>
          )}
        </VStack>
        <Box
          {...rest}
          alt="Supabase Image"
          onClick={isLoading ? undefined : handleClick}
          component="img"
          src={image}
          sx={{ objectFit: 'cover', ...sx }}
        />
      </Box>
    )
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify({
        src: prevProps.src,
        bucketId: prevProps.bucketId,
        id: prevProps.id,
        isShowSpeciesLabel: prevProps.isShowSpeciesLabel,
        isShowNoteLabel: prevProps.isShowNoteLabel,
        isShowCustomLabel: prevProps.isShowCustomLabel,
        sx: prevProps.sx,
      }) ==
      JSON.stringify({
        src: nextProps.src,
        bucketId: nextProps.bucketId,
        id: nextProps.id,
        isShowSpeciesLabel: nextProps.isShowSpeciesLabel,
        isShowNoteLabel: nextProps.isShowNoteLabel,
        isShowCustomLabel: nextProps.isShowCustomLabel,
        sx: nextProps.sx,
      })
    )
  },
)

export default ObservationImage
