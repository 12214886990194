import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material'

const MuiButton: {
  defaultProps: ComponentsProps['MuiTableHead']
  styleOverrides: ComponentsOverrides<Theme>['MuiTableHead']
} = {
  defaultProps: {},
  styleOverrides: {
    root: () => ({
      '& th:first-of-type': {
        borderRadius: '0',
      },
      '& th:last-of-type': {
        borderRadius: '0',
      },
    }),
  },
}

export default MuiButton
