import { SxProps } from '@mui/material'

export const container: SxProps = {
  width: 288,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRight: '1px solid #E4E7EC',
}
export const userEmail: SxProps = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '150px',
}
