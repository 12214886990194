import { Box } from '@mui/material'
import _ from 'lodash'
import React, { CSSProperties, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { v4 as uuidv4 } from 'uuid'
import { useMemoizedFn } from 'ahooks'

import { Loader, StickyLabel, StickyNote } from '@components/atom'
import { useLabelingContext } from '@context/LabelingContext'
import { LabelDto } from '@models/label.model'
import { INote, IPoint } from '@models/point.model'
import logger from '@utils/logger'
import Render from '@components/atom/Render/Render'

import * as styles from './LabelingImage.styles'
import LabelingPopover from './LabelingPopover/LabelingPopover'
import NoteNotationPopover from './NoteAnnotationPopover/NoteAnnotationPopover'
import { adjustCoordinateToOrigin, adjustOriginToToolCoordinate, getCursorByMode } from './LabelingImage.utils'
import { isSpeciesLabel } from '@utils/annotation'

export enum EMode {
  SpeciesLabel = 'species_label',
  CustomLabel = 'custom_label',
  HandFree = 'hand_free',
  Note = 'note',
  Cursor = 'cursor',
  GenericLabel = 'generic_label',
}

export type LabelingImageProps = {
  mode: EMode
  imageSrc: any
  loading?: boolean
  width: CSSProperties['width']
  height: CSSProperties['height']
  maxHeight: CSSProperties['height']
  onNewPointAdded?: (point: IPoint) => void
  onNewNoteAdded?: (note: INote) => void
  onNewNoteUpdated?: (note: INote) => void
  onNewPointCustomLabelAdded?: (point: IPoint) => void
  onRemovedPoint?: (pointId: string) => void
  onRemovedNote?: (noteId: string) => void
  collapseLabel: boolean
}

const LabelingImage = ({
  mode,
  width,
  height,
  maxHeight,
  imageSrc,
  loading,
  collapseLabel,
  onNewPointAdded,
  onNewNoteAdded,
  onNewNoteUpdated,
  onRemovedPoint,
  onRemovedNote,
}: LabelingImageProps) => {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)
  const [minScale, setMinScale] = useState<number>(0.05)
  const [currentPos, setCurrentPos] = useState<{ x: number; y: number } | null>(null)
  const [selectedNote, setSelectedNote] = useState<INote | null>(null)

  const { transformRef, setPoints, points, scale, setScale, updateNote, addNote, removeNote, notes } = useLabelingContext()
  const dummyElementRef = React.useRef<HTMLDivElement | null>(null)
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const clickable = useMemo(() => {
    return [EMode.CustomLabel, EMode.SpeciesLabel, EMode.GenericLabel, EMode.Note].includes(mode) && !loading
  }, [mode, loading])

  const handleImageClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!clickable) {
      return
    }
    if (!transformRef.current) {
      logger.error('Transform ref is not initialized')
      return
    }

    const rect = e.currentTarget.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top

    const {
      instance: { transformState },
    } = transformRef.current

    // const adjustedX = (x - transformState.positionX) / transformState.scale
    // const adjustedY = (y - transformState.positionY) / transformState.scale
    const [adjustedX, adjustedY] = adjustCoordinateToOrigin({
      x,
      y,
      transformX: transformState.positionX,
      transformY: transformState.positionY,
      scale: transformState.scale,
    })

    setCurrentPos({ x: adjustedX, y: adjustedY })

    // Create a dummy element at the adjusted position
    if (dummyElementRef.current) {
      dummyElementRef.current.style.left = `${x}px`
      dummyElementRef.current.style.top = `${y}px`
    }
    setPopoverOpen(true)
  }

  const handleSubmitLabel = (label: LabelDto, confidenceRate: number, note: string) => {
    if (currentPos) {
      const newPoint = {
        id: uuidv4(),
        label,
        x: currentPos.x,
        y: currentPos.y,
        confidenceRate,
        note,
      }
      setPoints([...points, newPoint])
      setPopoverOpen(false)
      onNewPointAdded?.(newPoint)
    }
  }

  const onZoom = useCallback(
    _.debounce(() => {
      const currentScale = transformRef.current?.instance?.transformState?.scale ?? 1
      setScale(currentScale)
    }, 50),
    [transformRef, transformRef.current],
  )

  const onClickRemovePoint = (pointId: string) => {
    setPoints(points.filter(p => p.id !== pointId))
    setPopoverOpen(false)
    onRemovedPoint?.(pointId)
  }

  const onClickRemoveNote = (noteId: string) => {
    removeNote(noteId)
    setPopoverOpen(false)
    onRemovedNote?.(noteId)
  }

  const handleImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const img = e.currentTarget
      const container = containerRef.current

      if (container) {
        const containerWidth = container.clientWidth
        const containerHeight = container.clientHeight
        const scaleWidth = containerWidth / img.naturalWidth
        const scaleHeight = containerHeight / img.naturalHeight
        const targetScale = Math.min(scaleWidth, scaleHeight)
        const nextTransform = _.cloneDeep({ ...transformRef.current?.instance?.transformState, scale: targetScale })
        transformRef.current?.setTransform(nextTransform.positionX ?? 0, nextTransform.positionY ?? 0, targetScale)
        setScale(targetScale)
        setMinScale(targetScale)
      }
    },
    [transformRef.current, containerRef.current],
  )

  const handleCreateNote = useMemoizedFn(values => {
    if (currentPos) {
      const newNote: INote = {
        id: values.id,
        note: values.note,
        x: currentPos.x,
        y: currentPos.y,
      }
      addNote(newNote)
      setPopoverOpen(false)
      onNewNoteAdded?.(newNote)
    }
  })

  const handleUpdateNote = useMemoizedFn(values => {
    if (currentPos) {
      const newNote: INote = {
        id: values.id,
        note: values.note,
        x: currentPos.x,
        y: currentPos.y,
      }
      updateNote(newNote)
      setPopoverOpen(false)
      onNewNoteUpdated?.(newNote)
    }
  })

  const selectNoteToEdit = (note: INote) => {
    setSelectedNote(note)
    if (!transformRef.current) {
      logger.error('Transform ref is not initialized')
      return
    }

    const {
      instance: { transformState },
    } = transformRef.current

    const [adjustedX, adjustedY] = adjustOriginToToolCoordinate({
      x: note.x,
      y: note.y,
      transformX: transformState.positionX,
      transformY: transformState.positionY,
      scale: transformState.scale,
    })

    setCurrentPos({ x: note.x, y: note.y })

    if (dummyElementRef.current) {
      dummyElementRef.current.style.left = `${adjustedX}px`
      dummyElementRef.current.style.top = `${adjustedY}px`
    }
    setCurrentPos({ x: note.x, y: note.y })
    setPopoverOpen(true)
  }

  useEffect(() => {
    if (!popoverOpen && selectedNote) {
      setSelectedNote(null)
    }
  }, [popoverOpen])

  return (
    <Render
      test={loading}
      truthy={
        <Box width={width} height={height} position="relative" bgcolor="grey.200">
          <Loader size="medium" color="dark" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
        </Box>
      }
      falsy={
        <Box sx={{ ...styles.container({ width, height, maxHeight }), backgroundColor: '#1E1E1E' }} ref={containerRef}>
          <Box onClick={handleImageClick} sx={styles.clickArea({ width, height, cursor: getCursorByMode(mode) })}>
            <TransformWrapper smooth minScale={minScale} initialScale={minScale} ref={transformRef} onZoom={onZoom}>
              <TransformComponent>
                <img src={imageSrc} alt="Labeling" style={{ borderRadius: '8px' }} onLoad={handleImageLoad} />
                {points.map((point, i) => (
                  <StickyLabel
                    id={point.id}
                    label={point.label?.name ?? ''}
                    note={point.note ?? ''}
                    confidenceRate={point.confidenceRate}
                    key={i}
                    x={point.x}
                    y={point.y}
                    type={isSpeciesLabel(point.label) ? 'species' : 'label'}
                    scale={scale}
                    onClickEdit={() => selectNoteToEdit({ id: point.id, x: point.x, y: point.y, note: point.note ?? '' })}
                    onDelete={onClickRemovePoint}
                    collapseLabel={collapseLabel}
                  />
                ))}

                {notes
                  .filter(note => note.id !== selectedNote?.id)
                  .map((note, i) => (
                    <StickyNote
                      id={note.id}
                      note={note.note ?? ''}
                      key={i}
                      x={note.x}
                      y={note.y}
                      scale={scale}
                      onClickEdit={() => selectNoteToEdit(note)}
                      onDelete={onClickRemoveNote}
                      collapseLabel={collapseLabel}
                    />
                  ))}
              </TransformComponent>
            </TransformWrapper>
          </Box>
          <Box ref={dummyElementRef} sx={styles.dummyElement} />
          {popoverOpen && !selectedNote && [EMode.SpeciesLabel, EMode.GenericLabel].includes(mode) && currentPos && (
            <LabelingPopover
              anchorElRef={dummyElementRef}
              mode={mode}
              open={popoverOpen}
              onClose={() => setPopoverOpen(false)}
              onSubmit={handleSubmitLabel}
            />
          )}
          {((popoverOpen && mode === EMode.Note && currentPos) || (popoverOpen && !!selectedNote)) && (
            <NoteNotationPopover
              mode={!!selectedNote ? 'edit' : 'create'}
              defaultValues={
                selectedNote
                  ? {
                      id: selectedNote?.id ?? '',
                      note: selectedNote?.note ?? '',
                    }
                  : undefined
              }
              anchorElRef={dummyElementRef}
              open={popoverOpen}
              onClose={() => setPopoverOpen(false)}
              onCreate={handleCreateNote}
              onUpdate={handleUpdateNote}
            />
          )}
        </Box>
      }
    />
  )
}

export default LabelingImage
