const ENV = {
  ENV: import.meta.env.VITE_ENV,
  SUPABASE_URL: import.meta.env.VITE_SUPABASE_URL,
  SUPABASE_KEY: import.meta.env.VITE_SUPABASE_KEY,
  MAP_STYLE: import.meta.env.VITE_MAP_STYLE,
  MAP_KEY: import.meta.env.VITE_MAP_KEY,
  USERBACK_KEY: import.meta.env.VITE_USERBACK_KEY,
  LABEL_SET_PLANT_SPECIES_ID: import.meta.env.VITE_LABEL_SET_PLANT_SPECIES_ID,
  APP_VERSION: import.meta.env.VITE_APP_VERSION,
  BUILD_DATETIME: import.meta.env.VITE_BUILD_DATETIME,
}

export default ENV
