import { ListSurveyProvider } from '@context/ListSurveyContext/ListSurveyContext'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

export const ListSurveyLayout = () => {
  return (
    <Suspense fallback={null}>
      <ListSurveyProvider>
        <Outlet />
      </ListSurveyProvider>
    </Suspense>
  )
}

export default ListSurveyLayout
