import type { SVGProps } from 'react'
const SvgIconSpecies = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 20} height={props.height || 20} fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15 8.334s-5 3.333-5 9.166M7.791 9.319a3.43 3.43 0 0 1-4.475-.323C1.296 6.976 1.7 2.533 1.7 2.533s4.443-.404 6.463 1.616c.8.8 1.122 1.896.966 2.934"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.47 10.681a3.047 3.047 0 0 1 .09-4.215c1.795-1.796 5.744-1.437 5.744-1.437s.36 3.95-1.436 5.745a3.04 3.04 0 0 1-2.284.89M5 5.834S10 10 10 17.5"
    />
  </svg>
)
export default SvgIconSpecies
