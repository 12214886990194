import { FormCatalog } from '@models/formCatalog.model'
import { MozaicSupebaseClient } from '@utils/supabase/MozaicSupebaseClient'
import { camelCase, mapKeys } from 'lodash'

export class FormCatalogService extends MozaicSupebaseClient {
  constructor() {
    super()
  }

  fetchAll = async (): Promise<{ data: FormCatalog[] | null; error: any }> => {
    try {
      const response = await this.client
        .from('form_catalogs')
        .select('id, version, schema, is_master, project_group_id, organisation_id, display_name')
      if (response.error) {
        return { data: null, error: response?.error }
      }
      const responseData = response.data.map((catalog: any) => mapKeys(catalog, (_, key) => camelCase(key)))
      return { data: responseData as FormCatalog[], error: response.error }
    } catch (error: any) {
      return { data: null, error }
    }
  }
}

const formCatalogService = new FormCatalogService()

export default formCatalogService
