import PrivateRoute from '@components/template/PrivateRoute'
import { lazy } from 'react'
import ERoutePaths from './routes.path'

const ReportsPage = lazy(() => import('@pages/ReportsPage/ReportsPage'))

const reportsRoute = {
  reports: {
    path: ERoutePaths.Reports,
    element: (
      <PrivateRoute title="Mozaic Earth Portal | Reports">
        <ReportsPage />
      </PrivateRoute>
    ),
  },
}

export default reportsRoute
