import { Box } from '@components/atom'
import './Loader.css'
import { SxProps } from '@mui/material'

export type LoaderProps = {
  size: 'small' | 'medium'
  color?: 'light' | 'dark'
  sx?: Omit<SxProps, 'marginLeft' | 'marginRight' | 'className'>
}

export const Loader = ({ size, color = 'light', sx }: LoaderProps) => {
  return <Box {...sx} marginLeft="auto" marginRight="auto" component="span" className={`loader loader-${size} color-${color} `} />
}

export default Loader
