import { useAppLoadingContext } from '@context/AppLoading'
import labelSetService from '@services/LabelSetService/LabelSetService'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'

function useQueryLabelSet() {
  const { setLoading } = useAppLoadingContext()

  const query = useQuery({
    queryKey: ['fetch-label-set'],
    queryFn: () => labelSetService.fetchAllLabelSets(),
  })

  useEffect(() => {
    setLoading(prev => ({
      ...prev,
      labelSets: query.isLoading,
    }))
  }, [query.isLoading])

  const labelSets = useMemo(() => query.data?.data ?? [], [query.data])

  return { labelSets, ...query }
}

export default useQueryLabelSet