//hidden scrollbar

import { SxProps } from '@mui/material'

export const hiddenScrollBar = {
  scrollbarWidth: 'none', // Firefox
  '&::-webkit-scrollbar': {
    display: 'none', // Chrome, Safari
  },
} as SxProps
