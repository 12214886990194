import { Stack, Typography } from '@components/atom'
import Icons from '@components/atom/Icons'
import { MozaicLogo } from '../../../icons'
import { useNavigate } from 'react-router-dom'

export type BrandLogoProps = {
  logoOnly: false
}

export const BrandLogo = () => {
  const navigate = useNavigate()
  return (
    <Stack gap={'10px'} padding={'0 8px'} alignItems={'center'} onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
      <Icons Icon={MozaicLogo} width={'44px'} height={'44px'} />
      <Stack spacing={0.5}>
        <Typography
          size="text-lg"
          weight="bold"
          sx={{
            fontSize: '1.125rem',
            lineHeight: '28px',
          }}
        >
          MOZAIC
        </Typography>
        <Typography
          size="text-lg"
          sx={{
            fontSize: '1.125rem',
            lineHeight: '28px',
          }}
        >
          EARTH
        </Typography>
      </Stack>
    </Stack>
  )
}

export default BrandLogo
