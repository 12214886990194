import { SxProps } from '@mui/material'
import { lightThemeDefault } from '@styles/theme/light'

export const container: SxProps = {
  display: 'flex',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
}

export const nextButton: SxProps = {
  top: '50%',
  transform: 'translateY(-55%)',
  position: 'absolute',
  right: 8,
  color: 'white',
  background: lightThemeDefault.palette?.green['600'],
  ':hover': {
    background: lightThemeDefault.palette?.green['800'],
  },
}

export const previousButton: SxProps = {
  top: '50%',
  transform: 'translateY(-55%)',
  position: 'absolute',
  left: 8,
  color: 'white',
  background: lightThemeDefault.palette?.green['600'],
  ':hover': {
    background: lightThemeDefault.palette?.green['800'],
  },
}
